import apiBase from './Query';

export async function createThread(title: string, content: string) {
  return apiBase({
    url: `/board/topics`,
    method: 'POST',
    data: {
      title,
      content,
    },
  });
}

export async function updateThread(id: number, title: string, content: string) {
  return apiBase({
    url: `/board/topics`,
    method: 'PUT',
    data: {
      id,
      title,
      content,
    },
  });
}

export function getThreadDetails(threadID: number) {
  return apiBase({
    method: 'GET',
    url: `/board/topic/${threadID}`,
  });
}

export function getThreads() {
  return apiBase({
    method: 'GET',
    url: `/board/topics/0`,
  });
}

export function addReply(threadID: number, comment: string) {
  return apiBase({
    url: `/board/topics/${threadID}/addreply`,
    method: 'POST',
    data: {
      content: comment,
    },
  });
}

export function closeThread(threadID: number) {
  return apiBase({
    url: `/board/topics/${threadID}`,
    method: 'DELETE',
  });
}
