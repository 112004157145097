import React from 'react';
import { Loading } from '../../Base';
import { getEventsAround } from '../../API';
import DayCell from './DayCell';
import Toolbar, { AdminBar, ToolbarItem } from '../../Common/Toolbar';
import { LinkButton } from '../../Common/Button';
import { nullthrows } from '../../Utility';
import './Calendar.css';

const ErrorBox = (props) => (
  <div className="error">{props.error.toString()}</div>
);

class Calendar extends React.Component {
  state = { ready: false, error: null, targetDate: null, weeks: null };

  static getDerivedStateFromProps(props, state) {
    const targetDate = Calendar.computeDate(props.match.params);
    if (
      !state.targetDate ||
      targetDate.year !== state.targetDate.year ||
      targetDate.month !== state.targetDate.month
    ) {
      return {
        ready: false,
        targetDate,
      };
    }
    // no need to refetch data if same month & year
    return null;
  }

  static computeDate({ year, month }) {
    if (year && month) {
      return {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
      };
    } else {
      const today = new Date();
      return {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
      };
    }
  }

  componentDidUpdate() {
    if (!this.state.ready) {
      this.loadData();
    }
  }

  componentDidMount() {
    this.setState({
      targetDate: Calendar.computeDate(this.props.match.params),
    });
  }

  loadData = async () => {
    const targetDate = nullthrows(
      this.state.targetDate,
      'Cannot load null target date'
    );
    try {
      const results = await getEventsAround(targetDate.year, targetDate.month);
      this.setState({
        ready: true,
        weeks: this.parseWeeks(targetDate, results.events),
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  renderBody() {
    const { weeks } = this.state;

    if (weeks == null) return null;

    return (
      <table className="calendar-cells">
        <thead>
          <tr>
            <th>일요일</th>
            <th>월요일</th>
            <th>화요일</th>
            <th>수요일</th>
            <th>목요일</th>
            <th>금요일</th>
            <th>토요일</th>
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, weekIndex) => (
            <tr key={weekIndex}>
              {week.map((day, dayIndex) => (
                <td key={dayIndex}>
                  <DayCell {...day} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const { error, ready, targetDate } = this.state;

    if (error) {
      return <ErrorBox error={error} />;
    }

    if (targetDate == null) {
      return <Loading />;
    }

    const prevMonth = {
      year: targetDate.month > 1 ? targetDate.year : targetDate.year - 1,
      month: targetDate.month > 1 ? targetDate.month - 1 : 12,
    };

    const nextMonth = {
      year: targetDate.month < 12 ? targetDate.year : targetDate.year + 1,
      month: targetDate.month < 12 ? targetDate.month + 1 : 1,
    };

    return (
      <div className="calendar">
        <h1>교육일정</h1>
        <AdminBar>
          <ToolbarItem>
            <LinkButton to={`/calendar/create`} buttonType="create">
              새 이벤트
            </LinkButton>
          </ToolbarItem>
        </AdminBar>
        <Toolbar>
          <ToolbarItem>
            <div className="calendar-toolbar">
              <LinkButton
                disabled={!ready}
                to={`/calendar/schedule/${prevMonth.year}/${prevMonth.month}`}>
                {`< ${prevMonth.month}월`}
              </LinkButton>
              <span className="current-month">
                {targetDate.year}년 / {targetDate.month}월
              </span>
              <LinkButton
                disabled={!ready}
                to={`/calendar/schedule/${nextMonth.year}/${nextMonth.month}`}>
                {`${nextMonth.month}월 >`}
              </LinkButton>
              {!ready && <Loading />}
            </div>
          </ToolbarItem>
        </Toolbar>
        {this.renderBody()}
      </div>
    );
  }

  parseWeeks(targetDate, events) {
    // simple calendar, just show current month
    const start = new Date(targetDate.year, targetDate.month - 1);
    start.setDate(1);
    while (start.getDay() > 0) {
      start.setDate(start.getDate() - 1);
    }

    const end = new Date(targetDate.year, targetDate.month - 1);
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);
    while (end.getDay() < 6) {
      end.setDate(end.getDate() + 1);
    }
    end.setDate(end.getDate() + 1); // top off to add last saturday

    const days = [];
    let eventIndex = 0;

    const iter = new Date(start);
    while (
      eventIndex < events.length &&
      new Date(events[eventIndex].eventTime) < iter
    ) {
      ++eventIndex;
    }
    while (iter < end) {
      const day = {
        date: new Date(iter.getTime()),
        events: [],
        dummy: false,
      };

      if (iter.getMonth() !== targetDate.month - 1) {
        day.dummy = true;
      } else {
        const threshold = new Date(day.date);
        threshold.setDate(threshold.getDate() + 1);
        while (
          eventIndex < events.length &&
          new Date(events[eventIndex].eventTime) < threshold
        ) {
          day.events.push(events[eventIndex]);
          ++eventIndex;
        }
      }

      days.push(day);
      iter.setDate(iter.getDate() + 1);
    }

    const weeks = days.reduce(
      (weeks, day) => {
        let lastWeek = weeks[weeks.length - 1];
        if (lastWeek.length === 7) {
          lastWeek = [];
          weeks.push(lastWeek);
        }
        lastWeek.push(day);
        return weeks;
      },
      [[]]
    );

    return weeks;
  }
}

export default Calendar;
