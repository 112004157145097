import React from 'react';

import { Board } from 'API';
import Loading from 'Base/Loading';
import { Button, LinkButton } from 'Common/Button';
import { Form, FormRow } from 'Common/Form';
import TextArea from 'Common/TextArea';
import { AdminBar, ToolbarItem } from 'Common/Toolbar';
import DateDisplay from 'Utility/DateDisplay';
import { nullthrows, toIDx } from 'Utility';
import { useUser } from 'Utility/User';

import DeleteThreadModal from './DeleteThreadModal';

function ThreadViewer(props) {
  const [comment, setComment] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [isDeleteModalShown, setIsDeleteModalShown] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [isWorking, setIsWorking] = React.useState(false);
  const [thread, setThread] = React.useState(null);
  const { history, match } = props;
  const threadID = toIDx(match.params.thread);
  const { user } = useUser();

  React.useEffect(
    function() {
      Board.getThreadDetails(threadID).then(function(thread) {
        setThread(thread);
        setIsReady(true);
        setIsWorking(false);
      });
    },
    [threadID]
  );

  const onSubmit = React.useCallback(
    async function() {
      try {
        setIsWorking(true);
        const results = await Board.addReply(nullthrows(thread).id, comment);

        if (results.success) {
          // clear form state
          setComment('');
          setMessage('성공적으로 댓글을 달았습니다');
        } else if (results.error) {
          throw results.error;
        }
      } finally {
        setIsWorking(false);
      }
    },
    [comment, thread]
  );

  const onDelete = React.useCallback(
    function() {
      history.push('/board');
    },
    [history]
  );

  if (!isReady) return <Loading />;
  if (thread == null) {
    throw new Error('Cannot render a null thread');
  }
  return (
    <div className="thread-viewer">
      <AdminBar>
        <ToolbarItem>
          <Button onClick={() => setIsDeleteModalShown(true)}>삭제</Button>
        </ToolbarItem>
        <ToolbarItem>
          <LinkButton to={match.url + '/edit'}>편집</LinkButton>
        </ToolbarItem>
      </AdminBar>
      <h1>{thread.title}</h1>
      <DateDisplay date={thread.createdOn} />
      <section className="content">{thread.description}</section>
      <h2>댓글 ({thread.comments.length})</h2>
      {user != null && (
        <Form onSubmit={onSubmit} successEl="댓글을 성공적으로 달았습니다.">
          <FormRow align="left" label="댓글 내용">
            <TextArea value={comment} onChange={setComment} />
          </FormRow>
          <FormRow align="left">
            <Button disabled={comment.length === 0 || isWorking}>달기</Button>
            {message && <p>{message}</p>}
          </FormRow>
        </Form>
      )}
      {thread.comments.map((comment, i) => {
        return (
          <div className="comment" key={i}>
            <p>{comment.description}</p>
          </div>
        );
      })}
      <DeleteThreadModal
        isShown={isDeleteModalShown}
        threadID={thread.id}
        onDelete={onDelete}
        onHide={() => setIsDeleteModalShown(false)}
      />
    </div>
  );
}

export default ThreadViewer;
