// @format
import React from 'react';
import {Link} from 'react-router-dom';
import {PageSpinner} from '../../Base';
import {Program} from '../../API';
import {AdminBar, ToolbarItem} from '../../Common/Toolbar';
import {LinkButton} from '../../Common/Button';
import Table from '../../Common/Table';
import memoize from '../../Utility/memoize';
import PaginationBrowser from '../../Utility/PaginationBrowser.js';

const COLS = ['ID', 'TITLE', 'DATE'];
const COLUMN_LABELS = Object.freeze({
  ID: '#',
  TITLE: '제목',
});

class Browser extends React.Component {
  buildRows = memoize(programs =>
    programs.map((program, i) => ({
      ID: i + 1,
      TITLE: (
        <Link to={`/programs/program/${program.id}`}>{program.title}</Link>
      ),
    })),
  );

  render() {
    const {history, location} = this.props;
    return (
      <div>
        <h1>교육 프로그램</h1>
        {this.renderTools()}
        <PaginationBrowser
          history={history}
          loadData={Program.getPrograms}
          location={location}
          spinner={<PageSpinner />}
          render={programs => (
            <Table
              rows={this.buildRows(programs)}
              cols={COLS}
              labels={COLUMN_LABELS}
            />
          )}
        />
      </div>
    );
  }

  renderTools() {
    const {match} = this.props;

    return (
      <AdminBar>
        <ToolbarItem>
          <LinkButton to={`${match.url}/new`} buttonType="create">
            새 프로그램
          </LinkButton>
        </ToolbarItem>
      </AdminBar>
    );
  }
}

export default Browser;
