import { Redirect } from 'react-router-dom';
import React from 'react';
import { PageSpinner } from '../../Base';
import { requireAdmin } from '../../Utility/User';
import TagEditor from '../../Common/TagEditor';
import { Form, FormRow } from '../../Common/Form';
import MarkdownEditor from '../../Common/MarkdownEditor';
import TextBox from '../../Common/TextBox';
import { Blog } from '../../API';
import { Button } from '../../Common/Button';

import {List} from 'immutable';

class Editor extends React.Component {
  state = {
    tags: List([]),
    title: '',
    content: '',
    message: null,
    ready: false,
    working: false,
  };

  updateTags = (newTags) => {
    this.setState({ tags: newTags });
  }

  updateTitle = (title) => {
    this.setState({ title });
  };

  updateContent = (content) => {
    this.setState({ content });
  };

  getID() {
    return parseInt(this.props.match.params.post, 10);
  }

  async componentDidMount() {
    const id = this.getID();
    if (id) {
      const {post, tags} = await Blog.getPostData(id);
      this.setState({
        tags: List(tags.map(tag => ({id: tag.id, name: tag.name}))),
        title: post.title,
        content: post.content,
        ready: true,
      });
    } else {
      this.setState({ ready: true });
    }
  }

  onSubmit = async () => {
    const id = this.getID();
    const { tags, title, content } = this.state;
    this.setState({ working: true });

    try {
      const result = id
        ? await Blog.updatePost({id, title, content, tags: tags.toArray() })
        : await Blog.uploadPost({tags: tags.toArray(), title, content});
      if (!result.success) {
        throw new Error(result.error || '오류가 발생했습니다.');
      }
    } finally {
      this.setState({ working: false });
    }
  };

  render() {
    const { ready, tags, title, content, working } = this.state;

    if (!ready) return <PageSpinner />;

    const id = this.getID();

    return (
      <Form
        name="blog"
        onSubmit={this.onSubmit}
        title={id ? '글 편집하기' : '새 블로그 글'}
        successEl={<Redirect to={`/blog${id ? '/post/' + id : ''}`} />}>
        <FormRow label="제목">
          <TextBox onChange={this.updateTitle} value={title} />
        </FormRow>
        <FormRow label="태그">
          <TagEditor tags={tags} onChange={this.updateTags} />
        </FormRow>
        <FormRow label="내용">
          <MarkdownEditor value={content} onChange={this.updateContent} />
        </FormRow>
        <FormRow>
          <Button
            buttonType="submit"
            type="submit"
            busy={working}
            disabled={!title.length}>
            확인
          </Button>
        </FormRow>
      </Form>
    );
  }
}

export default requireAdmin(Editor);
