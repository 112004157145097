import React from 'react';

export function joinClasses(
  ...classes: Array<string | false | null | undefined>
) {
  return classes.filter((c) => c).join(' ');
}

export function nullthrows<T>(input: T | null | undefined, error?: string): T {
  if (input == null) {
    throw new Error('Nullthrows exception: ' + (error || ''));
  }
  return input;
}

/**
Error box display component only for use in dev-mode
Is always rendered to null in production
 */
export function DebugBox(props: { error: Error | null }) {
  const { error } = props;
  if (process.env.NODE_ENV === 'development' && error != null) {
    return <div className="debug-box"> {error} </div>;
  }
  return null;
}

export function toIDx(urlParam: string | null, throwMsg: string) {
  const converted = parseInt(nullthrows(urlParam, throwMsg), 10);
  if (isNaN(converted)) {
    throw new Error(throwMsg);
  }
  return converted;
}

export function throttle(func: Function, interval: number) {
  let queued = false;
  let timeout: NodeJS.Timeout | null = null;
  return function() {
    if (!timeout) {
      func();
      queued = false;
      timeout = setTimeout(function() {
        timeout = null;
        if (queued) {
          func();
        }
      }, interval);
    } else {
      queued = true;
    }
  };
}
