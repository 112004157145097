import React from 'react';

import styles from './Footer.module.css';

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.contactInformation}>
        <section>
          <h2>주소</h2>
          서울시 마포구 마포대로 12
          <br />
          한신빌딩 207호
        </section>

        <section>
          <h2>이메일</h2>
          mapgong@naver.com
        </section>

        <section>
          <h2>카카오 ID</h2>
          맵공작소
        </section>
      </div>
      <div className={styles.copyright}>Copyright 2017-2023 @ 맵공</div>
    </div>
  );
}

export default Footer;
