import React from 'react';

import { useIsBusy } from './Form';
import { joinClasses } from '../Utility';
import './TextArea.css';

export default (props) => {
  const isBusy = useIsBusy();
  const { className, disabled, onChange, placeholder, value } = props;
  const handleChange = React.useCallback(
    (event) => onChange(event.currentTarget.value),
    [onChange]
  );
  return (
    <textarea
      className={joinClasses('ey-textarea', className)}
      disabled={disabled || isBusy}
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
    />
  );
};
