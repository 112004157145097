import { Redirect } from 'react-router-dom';
import React from 'react';
import { Program } from 'API';
import { PageSpinner } from 'Base';
import { Button, LinkButton } from 'Common/Button';
import { Form, FormRow } from 'Common/Form';
// import Uploader from 'Common/Uploader';
import Uploader from 'Common/UploaderV2';
import TextBox from 'Common/TextBox';
import TextArea from 'Common/TextArea';
import { requireAdmin } from 'Utility/User';

import Strings from './Editor.strings';

class Editor extends React.Component {
  state = {
    ready: false,
    title: '',
    content: '',
    working: false,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      const program = await Program.getProgram(id);
      this.setState({
        title: program.title,
        content: program.content,
        ready: true,
      });
    } else {
      this.setState({ ready: true });
    }
  }

  editTitle = (title) => {
    this.setState({ title });
  };

  editContent = (content) => {
    this.setState({ content });
  };

  onSubmit = async () => {
    const id = Number(this.props.match.params.id);
    const { title, content } = this.state;
    this.setState({ working: true });

    try {
      const results = !id
        ? await Program.createProgram(title, content)
        : await Program.updateProgram(id, title, content);
      if (!results.success) {
        throw new Error(results.error || '오류가 발생했습니다.');
      }
    } finally {
      this.setState({ working: false });
    }
  };

  render() {
    const { ready, title, content, working } = this.state;
    const id = this.props.match.params.id;
    const isNew = !id;

    if (!ready) return <PageSpinner />;

    return (
      <Form
        name="program"
        onSubmit={this.onSubmit}
        title={Strings.FORM_TITLE}
        successEl={<Redirect to="/programs" />}>
        <FormRow label={Strings.TITLE}>
          <TextBox onChange={this.editTitle} value={title} />
        </FormRow>

        <FormRow label={Strings.CONTENT}>
          <TextArea onChange={this.editContent} value={content} />
        </FormRow>
        {!isNew && (
          <FormRow label={Strings.UPLOAD}>
            <div style={{ width: '40em' }}>
              <Uploader
                endpoint={'/program/upload'}
                prepData={(fd) => {
                  fd.set('programID', id.toString());
                }}
              />
            </div>
          </FormRow>
        )}
        <FormRow>
          <Button
            type="submit"
            buttonType="submit"
            disabled={!title.length || working}>
            {Strings.SUBMIT}
          </Button>
          <LinkButton to={isNew ? `/programs/` : `/programs/program/${id}`}>취소</LinkButton>
        </FormRow>
      </Form>
    );
  }
}

export default requireAdmin(Editor);
