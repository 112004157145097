import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import ResetPW from './ResetPW';
import FindPW from './FindPW';
import Logout from './Logout';
import Options from './Options';
import {editablePageRoute} from '../../Common/EditablePage';

export default ({ match }) => {
  return (
    <Switch>
      <Route path={match.url + '/announcements'} render={editablePageRoute('member.announcements')} />
      <Route path={match.url + '/login'} component={Login} />
      <Route path={match.url + '/findpw'} component={FindPW} />
      <Route path={match.url + '/resetpw'} component={ResetPW} />
      <Route path={match.url + '/register'} component={Register} />
      <Route path={match.url + '/logout'} component={Logout} />
      <Route path={match.url + '/options'} component={Options} />
    </Switch>
  );
};
