import apiBase from './Query';
import Tag from '../Base/Types/Tag';

export function getBlog(page: number, tag?: number) {
  const data: {[index: string]: number} = {};
  if(tag != null){
    data['tag'] = tag;
  }
  return apiBase({
    bodyEncodingMethod: 'form',
    method: 'GET',
    url: `/blog/page/${page}`,
    data: {
      tag
    }
  });
}

export async function getPostCount(tag?: number) {
  const data = await apiBase({
    bodyEncodingMethod: 'form',
    method: 'GET',
    url: '/blog/count',
    data: {
      tag: tag != null ? tag : -1,
    }
  });
  return data.count;
}

export function getPost(id: number) {
  return apiBase({
    method: 'GET',
    url: `/blog/post/${id}`,
  });
}

export function getPostData(id: number) {
  return apiBase({
    bodyEncodingMethod: 'form',
    method: 'GET',
    url: `/blog/post/${id}`,
    data: {
      raw: true,
    },
  });
}

export function uploadPost(post: {
  tags: Array<Tag>,
  title: string, 
  content: string
}) {
  return apiBase({
    url: '/blog/post',
    method: 'POST',
    data: post,
  });
}

export function updatePost(post: {
  id: number, 
  tags: Array<Tag>,
  title: string, 
  content: string
}) {
  return apiBase({
    url: '/blog/post',
    method: 'PUT',
    data: post,
  });
}

export function deletePost(id: number){
  return apiBase({
    url: `/blog/post/${id}`,
    method: 'DELETE',
  })
}

export function searchTags(query: string) {
  return apiBase({
    bodyEncodingMethod: 'form',
    url: '/tags/query',
    method: 'GET',
    data: {
      query
    }
  });
}

export function getPopularTags(){
  return apiBase({
    url: '/tags/popular',
    method: 'GET',
  })
}
