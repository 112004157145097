import React from 'react';
import { Redirect } from 'react-router-dom';

import { tryLogin } from 'API';
import { Form, FormRow } from 'Common/Form';
import TextBox from 'Common/TextBox';
import { Button, LinkButton } from 'Common/Button';
import { useUser } from 'Utility/User';
import { NotificationContext } from 'Utility/Notification';

function Login(props) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isWorking, setIsWorking] = React.useState(false);
  const userContext = useUser();
  const { addNotification } = React.useContext(NotificationContext);
  const onSubmit = React.useCallback(
    async function() {
      setIsWorking(true);
      const result = await tryLogin(email, password);
      setIsWorking(false);
      if (result.success) {
        const {email, isAdmin, name} = result.data;
        userContext.setUser({
          email,
          isAdmin,
          name,
        });
        addNotification({
          type: 'success',
          message: <>안녕하세요 <strong>{name}</strong>님.</>,
        });
      } else {
        throw new Error('로그인정보가 옳지않습니다');
      }
    },
    [email, password, userContext, addNotification]
  );

  return (
    <Form
      onSubmit={onSubmit}
      title="로그인하기"
      successEl={<Redirect to="/" />}>
      <FormRow label="이메일">
        <TextBox value={email} onChange={setEmail} />
      </FormRow>
      <FormRow label="비밀번호">
        <TextBox
          isPassword={true}
          onChange={setPassword}
          value={password}
        />
      </FormRow>
      <FormRow>
        <Button buttonType="submit" type="submit" busy={isWorking} disabled={!email}>
          로그인
        </Button>
        <LinkButton to="findpw">비밀번호 찾기</LinkButton>
      </FormRow>
    </Form>
  );
}

export default Login;
