import * as React from 'react';

import Photo from '../../Base/Types/S3File';
import Image from '../../Common/SimpleImage';
import { joinClasses } from '../../Utility';
import './Slideshow.css';

interface Props {
  slides: ReadonlyArray<Photo>;
}

function Slideshow({slides}: Props) {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  React.useEffect(
    () => {
      const tid = setTimeout(function() {
        setCurrentSlide((currentSlide + 1) % slides.length);
      }, 7000);
      return () => clearTimeout(tid);
    },
    [currentSlide, setCurrentSlide, slides.length]
  );
  return (
    <div className="home-slideshow">
      <h1>
        <span className="emphasized">"</span>
        우리는
        <span className="emphasized">손</span>
        으로
        <span className="emphasized">생각</span>을 지어요
        <span className="emphasized">"</span>
      </h1>
      <div className="slideshow">
        {slides.map((photo, i) => (
          <Image
            className={joinClasses(
              'slide',
              i === currentSlide && 'current-slide'
            )}
            key={i}
            path={photo.resizedPath}
          />
        ))}
      </div>
    </div>
  );
}

export default Slideshow;
