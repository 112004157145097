import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, LinkButton } from 'Common/Button';
import { Loading } from 'Base';
import { Program, S3 } from 'API';
import { AdminBar } from 'Common/Toolbar';
import Modal from 'Base/Modal';
import { useIsAdmin } from 'Utility/User';
import TextBox from 'Common/TextBox';

function Viewer() {
  const { id: programID } = useParams();
  const history = useHistory();
  const [program, setProgram] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const reflectAttachmentUpdate = (uploadID: number, newName: string) => {
    const newProgram = { ...program };
    const attachment = newProgram.attachments.find(
      (e: any) => e.id === uploadID
    );
    attachment.name = newName;
    setProgram(newProgram);
  };

  const reflectAttachmentDeletion = (uploadID: number) => {
    const newProgram = { ...program };
    const index = newProgram.attachments.findIndex(
      (e: any) => e.id === uploadID
    );
    newProgram.attachments.splice(index, 1);
    setProgram(newProgram);
  };

  useEffect(
    () => {
      Program.getProgram(programID).then(setProgram);
    },
    [programID]
  );
  if (!program) {
    return <Loading />;
  }
  const { title, content, attachments } = program;
  return (
    <div>
      <h1>{title}</h1>
      <AdminBar>
        <LinkButton to={`/programs/edit/${programID}`}>편집</LinkButton>
        <Button buttonType="delete" onClick={() => setShowDeleteModal(true)}>
          삭제
        </Button>
      </AdminBar>
      <p>{content}</p>
      {attachments.length > 0 && (
        <>
          <h4>첨부된 파일들</h4>
          {attachments.map((attachment: any) => (
            <AttachmentLink
              attachment={attachment}
              onUpdate={reflectAttachmentUpdate}
              onDelete={reflectAttachmentDeletion}
            />
          ))}
        </>
      )}
      <Modal
        isShown={showDeleteModal}
        isBusy={isDeleting}
        footer={
          <>
            <Button onClick={() => setShowDeleteModal(false)}>취소</Button>
            <Button
              buttonType="delete"
              onClick={async () => {
                setIsDeleting(true);
                await Program.deleteProgram(programID);
                history.push('/programs/');
              }}>
              삭제하기
            </Button>
          </>
        }
        title="프로그램을 삭제하시겠습니까?">
        삭제하기
      </Modal>
    </div>
  );
}

function AttachmentLink({
  attachment,
  onUpdate,
  onDelete,
}: {
  attachment: any;
  onUpdate: (id: number, newName: string) => void;
  onDelete: (id: number) => void;
}) {
  const isAdmin = useIsAdmin();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState('');
  return (
    <div>
      {isAdmin && (
        <>
          <Button
            buttonType="edit"
            onClick={() => {
              setValue(attachment.name);
              setIsEditing(true);
            }}>
            수정
          </Button>
          <Button buttonType="delete" onClick={async () => {
            await Program.deleteAttachment(attachment.id);
            onDelete(attachment.id);
          }}>
            삭제
          </Button>
        </>
      )}
      {!isEditing ? (
        <a href={S3(attachment.path)} target="_blank" rel="noopener noreferrer">
          {attachment.name || '제목 없음'}
        </a>
      ) : (
        <>
          <TextBox value={value} onChange={setValue} />
          <Button
            buttonType="submit"
            onClick={async () => {
              await Program.updateAttachmentName(attachment.id, value);
              onUpdate(attachment.id, value);
              setIsEditing(false);
            }}>
            확인
          </Button>
          <Button
            buttonType="cancel"
            onClick={() => {
              setIsEditing(false);
            }}>
            취소
          </Button>
        </>
      )}
    </div>
  );
}

export default Viewer;
