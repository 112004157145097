export default function memoize(func) {
  if (typeof func !== 'function') {
    throw new Error('Non-function passed into memoize');
  }
  let lastVal, lastArgs;

  const fn = (...args) => {
    if (
      lastArgs &&
      lastArgs.length === args.length &&
      lastArgs.every((val, i) => val === args[i])
    ) {
      return lastVal;
    } else {
      lastArgs = args;
      lastVal = func.apply(null, args);
      return lastVal;
    }
  };
  return fn;
}
