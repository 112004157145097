import apiBase from './Query';

export async function requestResetPassword(email) {
  return apiBase({
    url: '/user/askresetpw',
    method: 'POST',
    bodyEncodingMethod: 'form',
    data: {
      email,
    },
  });
}

export async function changePassword(token, email, password) {
  return apiBase({
    url: '/user/resetpw',
    method: 'POST',
    bodyEncodingMethod: 'form',
    data: {
      token,
      email,
      password,
    },
  });
}

export async function changePasswordWithOldPassword(oldPassword, newPassword) {
  return apiBase({
    url: '/user/setpw',
    method: 'POST',
    bodyEncodingMethod: 'form',
    data: {
      oldPW: oldPassword,
      newPW: newPassword,
    },
  });
}

export async function changeName(name) {
  return apiBase({
    url: '/user/setname',
    method: 'POST',
    bodyEncodingMethod: 'form',
    data: {
      name,
    },
  });
}
