import React from 'react';
import TextArea from './TextArea';
import { UserErrorMessage } from './Alerts';
import { previewMarkdown } from '../API';
import { throttle } from '../Utility';
import MarkdownContent from './MarkdownContent';
import Loading from '../Base/Loading';
import './MarkdownEditor.css';

class MarkdownEditor extends React.Component {
  state = {
    error: null,
    isWaiting: false,
    // set to 0, any timestamp will always be greater
    renderedTimestamp: 0,
    previewHTML: '',
  };
  latestTimer = null;

  render() {
    const { error, isWaiting, previewHTML } = this.state;
    const { value, onChange } = this.props;
    return (
      <React.Fragment>
        <UserErrorMessage error={error} />
        <div className="markdown-editor">
          <section>
            <h4>
              마크다운 (
              <a
                href="https://gist.github.com/ihoneymon/652be052a0727ad59601"
                target="_blank"
                rel="noopener noreferrer">
                사용법
              </a>
              )
            </h4>
            <TextArea
              className="markdown-editor-box"
              value={value}
              onChange={onChange}
            />
          </section>
          <section>
            <h4>미리보기 {isWaiting && <Loading size={16} />}</h4>
            <div className="markdown-editor-preview markdown-editor-box">
              <MarkdownContent content={previewHTML} />
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }

  componentDidUpdate(newProps) {
    if (newProps.value !== this.props.value) {
      this.loadPreview();
    }
  }

  componentDidMount() {
    if (this.props.value.length) {
      this.loadPreview();
    }
  }

  loadPreview = throttle(async () => {
    const ts = Date.now();
    this.latestTimer = ts;
    this.setState({
      isWaiting: true,
    });
    try {
      const result = await previewMarkdown(this.props.value);
      const { renderedTimestamp } = this.state;
      if (ts > renderedTimestamp) {
        this.setState({
          previewHTML: result.html,
          renderedTimestamp: ts,
        });
      }
      if (ts === this.latestTimer) {
        this.setState({
          isWaiting: false,
        });
      }
    } catch (error) {
      this.setState({ error });
    }
  }, 300);
}

export default MarkdownEditor;
