import { ContentType } from 'Pages/Info/ContentBrowser';
import apiBase from './Query';
import S3 from './S3';

export async function createUpload(title: string, files: FileList, contentType: ContentType) {
  const fd = new FormData();
  fd.set('title', title);
  fd.set('contentType', contentType === ContentType.FILES ? 'files' : 'reports');
  Array.prototype.forEach.call(files, (file: File) => {
    fd.append('file', file);
  });

  return apiBase({
    url: '/s3/',
    method: 'POST',
    bodyEncodingMethod: 'native',
    data: fd,
  });
}

export async function renameUpload(id: number, title: string, description: string) {
  return apiBase({
    url: `/s3/upload/rename/${id}`,
    method: 'POST',
    bodyEncodingMethod: 'form',
    data: {
      title,
      description,
    },
  })
}

export async function deleteUpload(id: number) {
  return apiBase({
    url: `/s3/upload/${id}`,
    method: 'DELETE',
  });
}

export const filePath = S3;
