import React from 'react';

import Modal from 'Base/Modal';
import { Board } from 'API';
import { Button } from 'Common/Button';
import { DebugBox } from 'Utility';

export default class DeleteThreadModal extends React.Component {
  state = {
    errors: null,
    isDeleting: false,
  };

  render() {
    const { errors, isDeleting } = this.state;
    const { isShown, onHide } = this.props;

    return (
      <Modal
        footer={
          <React.Fragment>
            <Button disabled={isDeleting} onClick={onHide}>
              취소
            </Button>
            <Button
              busy={isDeleting}
              buttonType="submit"
              onClick={this.tryDeleteTopic}>
              확인
            </Button>
          </React.Fragment>
        }
        isShown={isShown}>
        <DebugBox error={errors && errors.toString()} />
        정말로 이 게시물을 삭제하시겠습니까?
      </Modal>
    );
  }

  tryDeleteTopic = async () => {
    this.setState({ isDeleting: true });
    try {
      const results = await Board.closeThread(this.props.threadID);
      if (!results.success) {
        throw new Error('게시물을 삭제하지못했습니다.');
      }
      this.props.onDelete();
    } catch (error) {
      this.setState({ errors: error });
    } finally {
      this.setState({ isDeleting: false });
    }
  };
}
