import { Redirect } from 'react-router-dom';
import * as React from 'react';

import { Page } from '../../API';
import { Button, LinkButton } from '../Button';
import { Form, FormRow } from '../Form';
import MarkdownEditor from '../MarkdownEditor';
import Loading from '../../Base/Loading';
import { UserErrorMessage } from '../Alerts';
import { requireAdmin } from '../../Utility/User';
import {
  INotificationContext,
  NotificationType,
  withNotifications,
} from '../../Utility/Notification';

interface Props extends INotificationContext {
  pageKey: string;
  returnURL: string;
}

interface State {
  content: string;
  error: Error | undefined;
  ready: boolean;
}

class Editor extends React.Component<Props, State> {
  state = {
    content: '',
    error: undefined,
    ready: false,
  };

  async componentDidMount() {
    try {
      const results = await Page.get(this.props.pageKey, true);
      if (results.success) {
        this.setState({ content: results.data.content });
      } else {
        throw new Error(
          results.error || '페이지 컨텐츠를 불러올수 없었습니다.'
        );
      }
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({
        ready: true,
      });
    }
  }

  render() {
    const { returnURL } = this.props;
    const { content, error, ready } = this.state;
    if (!ready) return <Loading />;
    return (
      <>
        <UserErrorMessage error={error} />
        <Form onSubmit={this.onSubmit} successEl={<Redirect to={returnURL} />}>
          <FormRow label="페이지 내용">
            <MarkdownEditor value={content} onChange={this.onChange} />
          </FormRow>
          <FormRow>
            <Button buttonType="submit">저장</Button>
            <LinkButton buttonType="return" to={returnURL} type="button">
              취소
            </LinkButton>
          </FormRow>
        </Form>
      </>
    );
  }

  onChange = (content: string) => {
    this.setState({
      content,
    });
  };

  onSubmit = async () => {
    const { pageKey, addNotification } = this.props;
    const results = await Page.save(pageKey, this.state.content);
    if (results.success !== true) {
      throw new Error(
        results.error || '페이지를 저장하는데 오류가 발생하였습니다.'
      );
    }
    addNotification({
      type: NotificationType.Success,
      message: '페이지 내용을 저장하였습니다.',
    });
  };
}

export default requireAdmin(withNotifications(Editor));
