import React from 'react';
import styles from './Hello.module.css';

function Hello() {
  return (
    <div className={styles.hello}>
      <h1>대표님 인사말</h1>
      <section>
        <p>인문교육, 예술 분야의 전문가들이 모인 비영리 사단법인으로 공교육 과정에 부족한 체험중심의 수작업 예술 교육 프로그램을 개발하여 아이들을 찾아가고자 합니다.</p>
        <p>‘손’을 쓰는 활동의 중요성을 잃어가는 아이들에게 머리보다 손을 쓰는 즐거움을 먼저 경험할 수 있게 하고, 손을 쓰면서 감춰진 자신의 힘을 찾을 수 있도록 합니다. 그 과정에서 머리로 생각하고 마음으로 느낄 수 있는 행복한 경험을 전해주고자 합니다.</p>
        <p>MAP교육공작소는 교육부가 고시하는 초∙중∙고등학교 교과과정에 따른 교육적 요구를 바탕으로 프로그램을 개발하고 있습니다.</p>
        <p>아이들은 MAP교육공작소에서 예술적이고 실제적인 수업을 받으며 자유롭게 사고하고 자신있게 행동하는 방법을 배우게 됩니다. 목공(woodwork), 드로잉(drawing) 등 다양한 수작업 예술 교육 프로그램을 통해 좀 더 창의적이고 자유로운 생각이 우리 사회에 널리 퍼지기를 기대합니다.</p>
      </section>
      <section className={styles.signature}>
        이사장 손은정
      </section>
    </div>
  );
}

export default Hello;