import * as React from 'react';
import { UserErrorMessage } from '../Common/Alerts';
import { Button } from '../Common/Button.js';
import { nullthrows } from '../Utility';
import './PaginationBrowser.css';
import { setQueryParameter, getQueryParameterAsNumber } from 'Pages/Blog/BlogUtil';

class PaginationBrowser extends React.Component {
  state = {
    data: [],
    page: null,
    ready: false,
    atEnd: false,
    count: null,
  };

  async componentDidMount() {
    await this.loadCount();
    await this.loadData(getQueryParameterAsNumber(this.props.location.search, 'page') || 0);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.categoryHash !== prevProps.categoryHash) {
      await this.loadCount();
      await this.loadData(0);
      this.setState({ page: 0 });
    }
    if (this.state.page !== prevState.page) {
      const { history, location } = this.props;
      const { pathname, search } = location;
      history.push(
        `${pathname}${setQueryParameter(
          search,
          'page',
          this.state.page.toString()
        )}`
      );
    }
  }

  async loadData(page, isRefresh) {
    this.setState({
      ready: false,
    });
    const { loadData } = this.props;
    // TODO add error handling
    const data = await loadData(page);

    this.setState({
        data,
        page,
      });
    this.setState({
      ready: true,
      atEnd: data.length === 0,
    });
  }

  reloadPage = async () => {
    this.loadData(
      this.state.page,
      true // isRefresh
    );
  };

  async loadCount() {
    const { loadCount } = this.props;
    if (loadCount == null) return;
    this.setState({
      count: await loadCount(),
    });
  }

  incrementPage = () => {
    this.loadData(this.state.page + 1);
  };

  decrementPage = () => {
    this.loadData(this.state.page - 1);
  };

  render() {
    const { render, spinner } = this.props;
    const { ready, data } = this.state;

    return (
      <>
        {ready && data ? render(data, this.reloadPage) : spinner}
        {this.renderPager()}
      </>
    );
  }

  renderPager() {
    const { count, ready, page, atEnd } = this.state;
    if (count != null) {
      const pageSize = nullthrows(
        this.props.pageSize,
        'Page Size prop must be passed in to use absolute pager'
      );
      const lower = Math.max(0, page - 4);
      const higher = Math.min(Math.ceil(count / pageSize), page + 4);

      const buttons = [];
      for (let i = lower; i < higher; ++i) {
        const pageNum = i + 1; // human friendly number
        if (i === page) {
          buttons.push(
            <span className="current-page" key={i}>
              {pageNum}
            </span>
          );
        } else {
          buttons.push(
            <Button key={i} onClick={() => this.loadData(i)}>
              {pageNum}
            </Button>
          );
        }
      }

      return <div className="mg-pager">{buttons}</div>;
    } else {
      return (
        <>
          <div className="mg-pager">
            <Button
              onClick={this.decrementPage}
              disabled={!ready || page === 0}
              size="small">
              {'전 페이지'}
            </Button>
            <span className="current-page">{page + 1}</span>
            <Button
              onClick={this.incrementPage}
              disabled={!ready || atEnd}
              size="small">
              {'다음 페이지'}
            </Button>
          </div>
          {atEnd && (
            <UserErrorMessage message="더이상 불러올 자료가 없습니다." />
          )}
        </>
      );
    }
  }
}

export default PaginationBrowser;
