import React from 'react';

// type TVideoInfo = {|
//   src: string,
//   title: string,
// |};

const VIDEOS = [
  {
    src: 'https://www.youtube.com/embed/SARl0-BkcqQ?controls=0',
    title: '서울새활용플라자 찾아가는 메이커',
  },
  {
    src: 'https://www.youtube.com/embed/2FiAuzWSF5I?controls=0',
    title: '서울새활용플라자_찾아가는 메이커',
  },
  {
    src: 'https://www.youtube.com/embed/_fII2xH5ldU?controls=0',
    title: '찾아가는 메이커 (동해교육도서관)',
  },
  {
    src: 'https://www.youtube.com/embed/irErNZkS9GA?controls=0',
    title: '선너머 종합사회복지관 찾아가는 메이커',
  },
];

class VideoBrowser extends React.Component {
  render() {
    return (
      <>
        <h1>영상 모음</h1>
        {VIDEOS.map((video, i) => (
          <div className="video-browser-video" key={i}>
            <iframe
              key={video.src}
              width="560"
              height="315"
              src={video.src}
              title={video.title}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        ))}
      </>
    );
  }
}

export default VideoBrowser;
