//      

                                                    

import React from 'react';
import { Link } from 'react-router-dom';
import './DayCell.css';

              
                 
             
                       
  

const DateOnly = ({ date }                ) => (
  <span className="date-print">
    {date.getMonth() + 1}/{date.getDate()}
  </span>
);

export default (props       ) => {
  const { dummy, date, events } = props;
  return (
    <div className={`day-cell ${dummy ? 'dummy' : ''}`}>
      <DateOnly date={date} />
      {events.map((event, i) => (
        <span key={i} className="day-cell-event">
          <Link to={`/calendar/event/${event.id}`}>{event.title}</Link>
        </span>
      ))}
    </div>
  );
};
