import apiBase from './Query';

export function get(pageKey: string, raw?: boolean){
  return apiBase({
    method: 'GET',
    bodyEncodingMethod: 'form',
    url: '/pages/',
    data: {
      pageKey,
      raw,
    }
  });
};

export function save(pageKey: string, content: string){
  return apiBase({
    method: 'POST',
    url: '/pages/',
    bodyEncodingMethod: 'form',
    data: {
      pageKey,
      content,
    },
  });
};
