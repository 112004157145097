import * as React from 'react';

export enum NotificationType{
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

export interface INotification {
  message: React.ReactNode;
  type: NotificationType;
}

export interface INotificationContext {
  addNotification: (notification: INotification) => void;
}

export const NotificationContext = React.createContext<INotificationContext>(
  {
    addNotification() {},
  }
);

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type WithoutNotifications<TProps extends INotificationContext> = Omit<
  TProps,
  'addNotification'
>;

export function withNotifications<TProps extends INotificationContext>(
  WrappedComponent: React.ComponentType<TProps>
) {
  return (props: WithoutNotifications<TProps>) => {
    const { addNotification } = React.useContext(NotificationContext);
    const combinedProps: TProps = {
      ...props,
      addNotification,
    } as TProps;
    return <WrappedComponent {...combinedProps} />;
  };
}
