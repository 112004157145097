// @format
import React from 'react';
import { tryRegister } from '../../API';
import { Button } from '../../Common/Button';
import { Form, FormRow } from '../../Common/Form';

export default class RegisterForm extends React.Component {
  state = {
    email: '',
    password: '',
    name: '',
    working: false,
    error: null,
  };

  onSubmit = async () => {
    const { email, password, name } = this.state;
    this.setState({ working: true });
    try {
      const result = await tryRegister({ email, password, name });
      if (!result.success) {
        throw new Error(result.error || '회원가입을 실패하였습니다.');
      }
    } finally {
      this.setState({ working: false });
    }
  };

  updaters = {};

  updateField(field) {
    if (!this.updaters[field])
      this.updaters[field] = (ev) =>
        this.setState({ [field]: ev.target.value });
    return this.updaters[field];
  }

  render() {
    const { email, name, password, working } = this.state;
    return (
      <div className="register-form">
        <Form
          title="회원가입하기"
          onSubmit={this.onSubmit}
          successEl="성공적으로 가입하였습니다."
        >
          <FormRow label="이메일">
            <input
              type="text"
              value={email}
              disabled={working}
              onChange={this.updateField('email')}
            />
          </FormRow>
          <FormRow label="닉네임">
            <input
              type="text"
              value={name}
              disabled={working}
              onChange={this.updateField('name')}
            />
          </FormRow>
          <FormRow label="비밀번호">
            <input
              type="password"
              value={password}
              disabled={working}
              onChange={this.updateField('password')}
            />
          </FormRow>
          <FormRow>
            <Button buttonType="submit" type="submit" busy={working}>
              확인
            </Button>
          </FormRow>
        </Form>
      </div>
    );
  }
}
