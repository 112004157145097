import React, {Component} from 'react';
import {Button} from '../../Common/Button.js';
import {Form, FormRow} from '../../Common/Form';
import {User} from '../../API';

export default class ResetPW extends Component{
  state = {
    working: false,
    errors: null,
    password: '',
  };

  parseURLParams(){
    const search = this.props.location.search.slice(1);
    const split = search.split('&');
    const result = {};

    split.forEach(value => {
      const chunks = value.match(/^([^=]+)=(.+)/);
      result[decodeURIComponent(chunks[1])] = decodeURIComponent(chunks[2]);
    });
    return result;
  }

  onSubmit = async () => {
    const {token, email} = this.parseURLParams();
    const {password} = this.state;
    this.setState({ working: true });

    try{
      const results = await User.changePassword(
        token,
        email,
        password
      );

      if(!results.success){
        throw new Error('비밀번호를 바꾸지 못했습니다.');
      }
    }
    finally{
      this.setState({ working: false });
    }
  }

  onChangePassword = (event) => {
    this.setState({
      password: event.target.value
    });
  };

  render(){
    const {token, email} = this.parseURLParams();

    return <Form onSubmit={this.onSubmit} title="비밀번호 변경하기" successEl="비밀번호를 성공적으로 바꿨습니다.">
      <input type="hidden" name="token" value={token} />
      <FormRow label="이메일">
        {email}
      </FormRow>
      <FormRow label="새 비밀번호">
        <input 
          type="password" 
          name="password"
          value={this.state.password}
          onChange={this.onChangePassword}
        />
      </FormRow>
      <FormRow>
        <Button buttonType="submit">확인</Button>
      </FormRow>
    </Form>
  }
}

