import apiBase from './Query';
import { nullthrows } from '../Utility';

export function createEvent(details) {
  return apiBase({
    url: '/event/event',
    method: 'POST',
    data: {
      title: details.title,
      description: details.description,
      eventTime: details.eventTime,
    },
    bodyEncodingMethod: 'form',
  });
}

export function deleteEvent(event) {
  return apiBase({
    url: `/event/event/${event}`,
    method: 'DELETE',
    bodyEncodingMethod: 'form',
  });
}

export function updateEvent(event) {
  return apiBase({
    url: `/event/event/${nullthrows(event.id)}`,
    method: 'PUT',
    bodyEncodingMethod: 'form',
    data: {
      title: event.title,
      description: event.description,
      eventTime: event.eventTime,
    },
  });
}
