import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ThreadBrowser from './ThreadBrowser';
import ThreadEditor from './ThreadEditor';
import ThreadViewer from './ThreadViewer';
import VideoBrowser from './VideoBrowser';
import { editablePageRoute } from '../../Common/EditablePage';

export default ({ match }) => {
  return (
    <Switch>
      <Route path={match.url + '/thread/create'} component={ThreadEditor} />
      <Route
        path={match.url + '/thread/:thread/edit'}
        component={ThreadEditor}
      />
      <Route path={match.url + '/thread/:thread'} component={ThreadViewer} />
      <Route
        path={match.url + '/media'}
        render={editablePageRoute('board.media')}
      />
      <Route path={match.url + '/videos'} component={VideoBrowser} />
      <Route path={match.url} component={ThreadBrowser} />
    </Switch>
  );
};
