import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Files from './Files';
import Reports from './Reports';

const Info = ({ match }) => {
  return (
  <Switch>
    <Route path={match.url + '/files'} component={Files} />
    <Route path={match.url + '/reports'} component={Reports} />
  </Switch>
)};

export default Info;
