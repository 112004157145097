import * as React from 'react';
import { Blog } from '../../API';
import Tag from '../../Base/Types/Tag';
import { Link } from 'react-router-dom';

import Styles from './BrowserTagNav.module.css';
import { Location } from 'history';
import { getQueryParameterAsNumber } from './BlogUtil';

export default function(props: { location: Location }) {
  const [popularTags, setPopularTags] = React.useState<Array<Tag> | null>(null);
  React.useEffect(() => {
    Blog.getPopularTags().then(setPopularTags);
  }, []);
  const filterTag = getQueryParameterAsNumber(props.location.search, 'tag');
  return (
    <div className={Styles.root}>
      <h1>토픽별</h1>
      {(popularTags || []).map((tag) => (
        <span
          className={tag.id === filterTag ? Styles.activeLink : Styles.tagLink}
          key={tag.id}>
          <Link to={`/blog?tag=${tag.id}`}>{tag.name}</Link>
          {tag.id === filterTag && <Link to="/blog">X</Link>}
        </span>
      ))}
    </div>
  );
}
