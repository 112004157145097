import React from 'react';
import styles from './Article.module.css';

export const Article = ({ children }) => (
  <div className={styles.root}>{children}</div>
);

export const ArticleTitle = ({ children }) => (
  <h1 className={styles.title}>{children}</h1>
);

export const ArticleBody = ({ children }) => (
  <section className={styles.body}>{children}</section>
);
