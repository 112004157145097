//       strict
import React from 'react';
import { Form, FormRow } from '../../Common/Form';
import TextBox from '../../Common/TextBox';
import {Button} from '../../Common/Button';
import {User} from '../../API';

               
                
                 
                 
   

class SetPasswordForm extends React.Component            {
  state         = {
    oldPW: '',
    newPW1: '',
    newPW2: '',
  };

  render() {
    const {oldPW, newPW1, newPW2} = this.state;
    const labelWidth = "10rem";
    return (
      <Form
        onSubmit={this.onSubmit}
        title="비밀번호 변경"
        validate={this.validate}
        successEl="비밀번호를 성공적으로 바꿨습니다."
      >
        <FormRow label="현재 비밀번호" labelWidth={labelWidth}>
          <TextBox
            isPassword={true}
            value={oldPW}
            onChange={this.updateOldPW}
          />
        </FormRow>
        <FormRow label="새 비밀번호" labelWidth={labelWidth}>
          <TextBox
            isPassword={true}
            value={newPW1}
            onChange={this.updateNewPW1}
          />
        </FormRow>
        <FormRow label="비밀번호 재입력" labelWidth={labelWidth}>
          <TextBox
            isPassword={true}
            value={newPW2}
            onChange={this.updateNewPW2}
          />
        </FormRow>
        <FormRow labelWidth={labelWidth}>
          <Button type="submit">변경하기</Button>
        </FormRow>
      </Form>
    );
  }

  onSubmit = async () => {
    const { oldPW, newPW1 } = this.state;
    const results = await User.changePasswordWithOldPassword(oldPW, newPW1);
    if (!results.success) {
      throw new Error(
        results.error || '비밀번호를 변경하는중 오류가 발생하였습니다.'
      );
    }
  };

  updateOldPW = (pw        ) => {
    this.setState({ oldPW: pw });
  };

  updateNewPW1 = (pw        ) => {
    this.setState({ newPW1: pw });
  };

  updateNewPW2 = (pw        ) => {
    this.setState({ newPW2: pw });
  };

  validate = ()       => {
    const { newPW1, newPW2 } = this.state;
    if (newPW1 !== newPW2) {
      throw new Error('비밀번호가 일치하지 않습니다.');
    }
  };
}

export default SetPasswordForm;