import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Browser from './Browser';
import Editor from './Editor';
import PostViewer from './PostViewer';

function Blog(props) {
  const url = props.match.url;
  return (
    <Switch>
      <Route exact path={`${url}/new`} component={Editor} />
      <Route path={`${url}/post/:post`} component={PostViewer} />
      <Route path={`${url}/edit/:post`} component={Editor} />
      <Route path={url} component={Browser} />
    </Switch>
  );
}

export default Blog;
