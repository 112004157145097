import { Redirect } from 'react-router-dom';
import React from 'react';
import { Form, FormRow } from '../../Common/Form';
import TextArea from '../../Common/TextArea';
import TextBox from '../../Common/TextBox';
import { Button } from '../../Common/Button';
import Alert from '../../Common/Alerts';
import Strings from './ThreadEditor.strings.js';
import { Board } from '../../API';

export default class ThreadEditor extends React.Component {
  state = {
    error: null,
    title: '',
    content: '',
    isFetching: false,
  };

  async componentDidMount() {
    const threadID = this.props.match.params.thread;
    if (threadID == null) {
      return;
    }
    this.setState({ isFetching: true });
    try {
      const thread = await Board.getThreadDetails(threadID);
      this.setState({
        title: thread.title,
        content: thread.description,
      });
    } catch (error) {
      this.setState({
        error: Error(
          '게시판 글 정보를 불러오는데 문제가 있었습니다. 글 ID를 다시한번 확인해주세요.'
        ),
      });
    } finally {
      this.setState({ isFetching: false });
    }
  }

  changeTitle = (title) => {
    this.setState({ title });
  };

  changeContent = (content) => {
    this.setState({ content });
  };

  onSubmit = async () => {
    const { title, content } = this.state;
    const { match } = this.props;
    const threadID = match.params.thread;
    if (threadID) {
      const results = await Board.updateThread(threadID, title, content);
      if (!results.success) {
        throw new Error('처리하는데 오류가 발생했습니다');
      }
    } else {
      const results = await Board.createThread(title, content);
      if (!results.success) {
        throw new Error(
          results.error || '게시물을 올리는데 오류가 발생하였습니다.'
        );
      }
    }
  };

  render() {
    const { error, title, content } = this.state;
    if (error) {
      return <Alert message={error.message} type="error" />;
    }
    return (
      <Form
        onSubmit={this.onSubmit}
        title={Strings.FORM_TITLE}
        successEl={<Redirect to="/board" />}>
        <FormRow label={Strings.THREAD_TITLE}>
          <TextBox
            placeholder={Strings.THREAD_TITLE_PLACEHOLDER}
            value={title}
            onChange={this.changeTitle}
          />
        </FormRow>
        <FormRow label={Strings.THREAD_CONTENT}>
          <TextArea
            placeholder={Strings.THREAD_CONTENT_PLACEHOLDER}
            value={content}
            onChange={this.changeContent}
          />
        </FormRow>
        <FormRow>
          <Button type="submit" buttonType="submit" disabled={!title.length}>
            {Strings.SUBMIT}
          </Button>
        </FormRow>
      </Form>
    );
  }
}
