import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Browser from './Browser';
import SlideshowEditor from './SlideshowEditor';

export default class Gallery extends React.Component {
  render() {
    const { match: {url} } = this.props;

    return (
      <Switch>
        <Route path={`${url}/slideshow`} component={SlideshowEditor} />
        <Route render={() => <Browser parent={0} />} />
      </Switch>
    );
  }
}
