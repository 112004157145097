import * as React from 'react';
import { Link } from 'react-router-dom';

import {useIsBusy} from './Form';
import Loading from '../Base/Loading';
import { joinClasses } from '../Utility';
import './Button.css';

const BUTTON_TYPE_CLASSES = Object.freeze({
  action: 'mg-action-button',
  create: 'mg-create-button',
  delete: 'mg-delete-button',
  return: 'mg-return-button',
  submit: 'mg-submit-button',
  link: 'mg-link-button',
});

const BUTTON_SIZE_CLASSES = Object.freeze({
  small: 'mg-button-small',
  medium: 'mg-button-medium',
  large: 'mg-button-large',
});

export function Button(props) {
  const { busy: busyProp, buttonType, children, className, size, ...other } = props;
  const busy = useIsBusy() || busyProp;
  const disabled = props.disabled || busy;
  return (
    <button
      {...other}
      disabled={disabled}
      className={joinClasses(
        'mg-button',
        buttonType && BUTTON_TYPE_CLASSES[buttonType],
        size && BUTTON_SIZE_CLASSES[size],
        disabled && 'disabled',
        className
      )}>
      {children}
      {(busyProp || (busy && buttonType === 'submit')) && <Loading />}
    </button>
  );
}

export function LinkButton({ to, children, ...props }) {
  return (
    <Link to={to}>
      <Button buttonType="link" {...props}>
        {children}
      </Button>
    </Link>
  );
}
