import * as React from 'react';
import styles from './Modal.module.css';
import { joinClasses } from 'Utility';
import { FormContext } from '../Common/Form';

interface ModalProps {
  children: React.ReactNode;
  footer: React.ReactNode;
  isBusy?: boolean;
  isShown: boolean;
  title: string | null;
}

function Modal(props: ModalProps) {
  const { title, children, footer, isShown, isBusy = false } = props;
  const formContext = React.useMemo(
    () => ({
      isBusy,
    }),
    [isBusy]
  );
  return (
    <div className={joinClasses(styles.canvas, isShown && styles.active)}>
      <FormContext.Provider value={formContext}>
        <div className={styles.modal}>
          {title != null && <div className={styles.title}>{title}</div>}
          <div className={styles.body}>{children}</div>
          <div className={styles.footer}>{footer}</div>
        </div>
      </FormContext.Provider>
    </div>
  );
}

export default Modal;
