import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProgramBrowser from './Browser';
import ProgramViewer from './Viewer';
import ProgramEditor from './Editor';

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}/new`} component={ProgramEditor} />
      <Route exact path={`${match.url}/edit/:id`} component={ProgramEditor} />
      <Route path={match.url + '/program/:id'} component={ProgramViewer} />
      <Route path={match.url + '/'} component={ProgramBrowser} />
    </Switch>
  );
};
