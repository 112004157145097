import React from 'react';
import { AdminOnly } from '../Utility/User';
import './Toolbar.css';

export default function Toolbar({ children }) {
  return <div className="toolbar">{children}</div>;
}

export function ToolbarItem({ children }) {
  return <div className="toolbar-item">{children}</div>;
}

export function AdminBar({ children }) {
  return (
    <AdminOnly>
      <Toolbar>{children}</Toolbar>
    </AdminOnly>
  );
}
