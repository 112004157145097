import * as React from 'react';

import styles from './Drawer.module.css';

interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
}

export default function(props: Props) {
  const { children, header } = props;
  return (
    <div className={styles.root}>
      <div className={styles.header}>{header}</div>
      <div className={styles.body}>{children}</div> 
    </div>
  );
}
