import * as Program from './Program';
import * as Blog from './Blog';
import * as Gallery from './Gallery';
import * as Board from './Board';
import * as Event from './Event';
import * as Upload from './Upload';
import * as User from './User';
import * as Page from './Page';
import * as Folder from './Folder';
import S3 from './S3';
import apiBase from './Query';

export { Program, Blog, Gallery, Board, Event, Upload, User, S3, Page, Folder };

export function getNews() {
  return apiBase({
    method: 'GET',
    url: `/timeline/recent`,
  });
}

export async function getSlides() {
  return apiBase({
    method: 'GET',
    url: '/gallery/slides'
  });
}

export function getFiles(page, type) {
  return apiBase({
    method: 'GET',
    url: `/s3/page/${page}?contentType=${type}`,
  });
}

export function getEventsAround(year, month) {
  return apiBase({
    method: 'GET',
    url: `/event/month/${year}/${month}`,
  });
}

export async function getRecentEvents() {
  const current = new Date();

  // TODO assume user is in Korean timezone for now...
  return apiBase({
    method: 'GET',
    url: `/event/month/${current.getFullYear()}/${current.getMonth() + 1}`,
  });
}

export function getEventDetails(eventID) {
  return apiBase({
    method: 'GET',
    url: `/event/event/${eventID}`,
  });
}

export function tryLogin(email, password) {
  return apiBase({
    url: `/user/login`,
    method: 'POST',
    bodyEncodingMethod: 'form',
    data: {
      email,
      password,
    },
  });
}

export function tryLogout() {
  return apiBase({
    url: '/user/logout',
    method: 'POST',
  });
}

export function getUserInfo() {
  return apiBase({
    url: `/user/status`,
    method: 'GET',
  });
}

export function tryRegister({ email, password, name }) {
  return apiBase({
    url: '/user/register',
    method: 'POST',
    bodyEncodingMethod: 'form',
    data: {
      email,
      password,
      name,
    },
  });
}

export async function uploadGeneric(endpoint, files, clientKey) {
  if (!files.length) {
    return true;
  }

  const data = new FormData();
  for (const file of files) {
    data.append('files', file, file.name);
  }
  // TODO clean this up, should be a separate fxn
  if(clientKey != null){
    data.append('clientKey', clientKey);
  }
  return apiBase({
    url: endpoint,
    method: 'POST',
    bodyEncodingMethod: 'native',
    data,
  });
}

export async function previewMarkdown(content) {
  return apiBase({
    url: '/markdown/preview',
    method: 'POST',
    bodyEncodingMethod: 'form',
    data: {
      content,
    },
  });
}
