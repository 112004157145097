/*       */
import React from 'react';
import { Link } from 'react-router-dom';
import './NewsList.css';

             
                    
                      
             
                    
                  
  

                       
                             
   

const NewsList = (props               ) => {
  const { news } = props;

  return (
    <div className="news-list">
      <h1>최근 소식들</h1>

      <ul>
        {news.map((item, i) => (
          <li key={i}>
            <NewsItem {...item} />
          </li>
        ))}
      </ul>
    </div>
  );
};

function getItemURL(type, id) {
  switch (type) {
    case 'event':
      return `/calendar/event/${id}`;
    case 'program':
      return `/programs/program/${id}`;
    case 'upload':
      return `/upload/${id}`;
    case 'blog_post':
      return `/blog/post/${id}`;
    default:
      return '#';
  }
}

const NewsItem = (event      ) => {
  const { eventType, eventID, description } = event;
  return (
    <div className="news-item">
      <Link className="news-description" to={getItemURL(eventType, eventID)}>
        {description}
      </Link>
    </div>
  );
};

export default NewsList;
