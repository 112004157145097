import React from 'react';
import { List, Set } from 'immutable';

import Photo from '../../Base/Types/S3File';
import { Button } from '../../Common/Button';
import Image from '../../Common/SimpleImage';
import './SlideshowPhotoPicker.css';

interface Props {
  addedSlides: List<Photo>;
  images: Array<Photo>;
  isBusy: boolean;
  onAdd: (photo: Photo) => void;
}

function SlideshowPhotoPicker(props: Props) {
  const { addedSlides, images, isBusy, onAdd } = props;
  const slideIDs = React.useMemo(
    () => Set(addedSlides.map((slide) => slide.id)),
    [addedSlides]
  );
  return (
    <div className="slideshow_photo_picker">
      {images.map((image, i) => {
        const isDisabled = !onAdd || isBusy || slideIDs.contains(image.id);
        return (
          <div className="item" key={i}>
            <Image
              className={isDisabled ? 'disabled' : ''}
              path={image.thumbnailPath}
            />
            {!isDisabled && (
              <Button className="add_button" onClick={() => onAdd(image)}>
                +
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default SlideshowPhotoPicker;
