import apiBase from './Query';
import S3 from './S3';

export async function uploadPhotos(files) {
  if (!files.length) {
    return true;
  }

  const data = new FormData();
  for (const file of files) {
    data.append('files', file, file.name);
  }

  return apiBase({
    url: `/gallery/`,
    method: 'POST',
    data,
    bodyEncodingMethod: 'native',
  });
}

export const getUploadEndpoint = () => `/gallery/`;

export const getPhotos = async (parent) =>
  apiBase({
    method: 'GET',
    url: `/gallery/${parent}`,
  }).then((results) => ({
    currentFolder: results.currentFolder,
    photos: results.data,
    folders: results.folders,
  }));

export const imageURI = (path) => S3(`gallery/${path}`);

export const rotateImage = (image, degrees) =>
  apiBase({
    bodyEncodingMethod: 'form',
    method: 'POST',
    url: `/gallery/rotate_photo/${image}`,
    data: {
      degrees,
    },
  });

export function deleteImage(image) {
  return apiBase({
    bodyEncodingMethod: 'form',
    method: 'DELETE',
    url: `/gallery/delete_photo/${image}`,
  });
}

export function fetchAllImages() {
  return apiBase({
    method: 'GET',
    url: '/gallery/all/0',
  });
}

export function fetchActiveSlides() {
  return apiBase({
    method: 'GET',
    url: '/gallery/slides',
  });
}

export function saveImages(slides) {
  return apiBase({
    bodyEncodingMethod: 'json',
    method: 'POST',
    url: '/gallery/slideshow',
    data: {
      slides,
    },
  });
}

export async function getRecentAlbums(){
  return [
    {
      id: 1,
      title: 'Dummy Album 1',
      coverPhoto: {
        id: 23,
        path: 'testpath.jpg',
        fullsizePath: 'huh.jpg',
        resizedPath: 'huh.jpg',
        thumbnailPath: 'huh.jpg',
      },
    },
    {
      id: 2,
      title: 'Dummy Album 2',
      coverPhoto: {
        id: 23,
        path: 'testpath.jpg',
        fullsizePath: 'huh.jpg',
        resizedPath: 'huh.jpg',
        thumbnailPath: 'huh.jpg',
      },
    },
  ]
  /*return apiBase({
    method: 'GET',
    url: '/gallery/albums/',
  })*/
}

export async function createAlbum(input){
  return apiBase({
    method: 'POST',
    url: '/gallery/albums/',
    bodyEncodingMethod: 'form',
    data: {
      input_name: input.name,
      input_parent: input.parent,
    }
  })
}

export async function moveItem(id, folder){
  return apiBase({
    method: 'POST',
    url: '/gallery/move_item',
    bodyEncodingMethod: 'json',
    data: {
      itemID: id,
      parent: folder,
    }
  })
}

export async function reorder(parent, order){
  return apiBase({
    method: 'POST',
    url: '/gallery/set_order',
    bodyEncodingMethod: 'json',
    data: {
      parent,
      order,
    }
  })
}
