import React, { useMemo } from 'react';
import {useLocation, useHistory, useRouteMatch} from 'react-router-dom';

import { Loading } from '../../Base';
import Modal from '../../Base/Modal';
import { getFiles, Upload } from '../../API';
import { AdminBar } from '../../Common/Toolbar';
import { Button, LinkButton } from '../../Common/Button';
import { TableV2 } from '../../Common/Table';
import {
  NotificationContext,
  NotificationType,
} from '../../Utility/Notification';
import PaginationBrowser from '../../Utility/PaginationBrowser';
import { useUser } from '../../Utility/User';
import TextBox from '../../Common/TextBox';
import { nullthrows } from '../../Utility';
import { FormRow } from '../../Common/Form';

interface UploadFile {
  id: number;
  path: string;
  title: string;
  description: string;
}

interface Props{
  type: ContentType;
}

export enum ContentType{
  FILES,
  REPORTS,
}

export default function ContentBrowser({type}: Props) {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <div className="file-browser">
      <h1>{type === ContentType.FILES ? '자료실' : '연차 보고서'}</h1>
      <AdminBar>
        <LinkButton to={`${match.url}/upload`} buttonType="create">
          자료 올리기
        </LinkButton>
      </AdminBar>
      <PaginationBrowser
        loadData={(page: any) => getFiles(page, serializeContentType(type))}
        location={location}
        history={history}
        spinner={<Loading />}
        render={(files: UploadFile[], reload: () => void) => (
          <TableRenderer files={files} reload={reload} />
        )}
      />
    </div>
  );
}

function TableRenderer(props: {
  files: UploadFile[];
  reload: () => void;
}) {
  const { files, reload } = props;
  const [modalState, setModalState] = React.useState<null | {
    file: UploadFile;
    action: 'UPDATE' | 'DELETE';
  }>(null);
  const [newTitle, setNewTitle] = React.useState('');
  const [newDescription, setNewDescription] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const { user } = useUser();
  const { addNotification } = React.useContext(NotificationContext);
  async function deleteFile() {
    // assert that file isn't null
    const { file } = nullthrows(
      modalState,
      'Modal State should never be null when deleteFile is called'
    );
    setIsLoading(true);
    try {
      await Upload.deleteUpload(file.id);
      setModalState(null);
      addNotification({
        type: NotificationType.Success,
        message: (
          <div>
            <strong>{file.title}</strong>을 삭제하였습니다.
          </div>
        ),
      });
      reload();
    } finally {
      setIsLoading(false);
    }
  }
  async function renameUpload() {
    const { file } = nullthrows(
      modalState,
      'ModalState should never be null here'
    );
    setIsLoading(true);
    try {
      await Upload.renameUpload(file.id, newTitle, newDescription);
      setModalState(null);
      addNotification({
        type: NotificationType.Success,
        message: <div>성공적으로 파일을 업데이트했습니다.</div>,
      });
      reload();
    } finally {
      setIsLoading(false);
    }
  }
  const cols = useMemo(
    () => {
      const tmpColumns = [
        {
          name: '파일명',
          renderer: (upload: UploadFile) => (
            <a href={Upload.filePath(upload.path)} download>
              {upload.title}
            </a>
          ),
        },
        {
          name: '설명',
          renderer: (upload: UploadFile) => upload.description,
        },
      ];
      if (user && user.isAdmin) {
        tmpColumns.push({
          name: '',
          renderer: (file: UploadFile) => (
            <>
              <Button
                buttonType="delete"
                onClick={() =>
                  setModalState({
                    action: 'DELETE',
                    file,
                  })
                }>
                삭제
              </Button>
              <Button
                buttonType="edit"
                onClick={() => {
                  setNewTitle(file.title);
                  setNewDescription(file.description);
                  setModalState({
                    action: 'UPDATE',
                    file,
                  });
                }}>
                편집
              </Button>
            </>
          ),
        });
      }
      return tmpColumns;
    },
    [user]
  );
  return (
    <>
      <TableV2 cols={cols} rows={files} />
      {modalState &&
        modalState.action === 'DELETE' && (
          <Modal
            footer={
              <>
                <Button onClick={() => setModalState(null)}>취소</Button>
                <Button onClick={deleteFile} busy={isLoading}>
                  확인
                </Button>
              </>
            }
            isShown={true}
            title="삭제하기">
            <>
              정말로 <strong>{modalState.file.title}</strong>을
              삭제하시겠습니까?
            </>
          </Modal>
        )}
      {modalState &&
        modalState.action === 'UPDATE' && (
          <Modal
            footer={
              <>
                <Button onClick={() => setModalState(null)}>취소</Button>
                <Button
                  disabled={!newTitle}
                  onClick={renameUpload}
                  busy={isLoading}>
                  확인
                </Button>
              </>
            }
            isShown={true}
            title="삭제하기">
            <>
              <FormRow label="파일명">
                <TextBox value={newTitle} onChange={setNewTitle} />
              </FormRow>
              <FormRow label="추가 내용">
                <TextBox value={newDescription} onChange={setNewDescription} />
              </FormRow>
            </>
          </Modal>
        )}
    </>
  );
}

export function serializeContentType(type: ContentType){
  switch(type){
    case ContentType.FILES:
      return 'files';
    case ContentType.REPORTS:
      return 'reports';
  }
}