import Immutable from 'immutable';
import React, { useReducer, useState, useEffect, Dispatch } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { Gallery, Folder } from 'API';
import Loading from 'Base/Loading';
import Modal from 'Base/Modal';
import { Button } from 'Common/Button';
import MGVideo from 'Common/MGVideo';
import GalleryImage from 'Common/GalleryImage';
import TextBox from 'Common/TextBox';
import Uploader from 'Common/UploaderV2';
import { joinClasses } from 'Utility';
import {getEventSource, parseSSEEvent} from 'API/Query';
import { useUser, useIsAdmin } from 'Utility/User';

import styles from 'Common/PhotoList.module.css';
import BrowserStyles from './AdminBrowser.module.css';
import { UserErrorMessage } from 'Common/Alerts';

enum ActionTypes {
  REQUEST,
  LOAD,
  MOVE_ITEM,
  DELETE_ITEM,
  REORDER_ITEM,
  // Add item to local collection state
  // when successfully uploaded
  ADD_ITEM,
  CREATE_FOLDER,
  RENAME_FOLDER,
  DELETE_FOLDER,
}

enum DragTypes {
  FOLDER = 'folder',
  MEDIA = 'media',
}

interface InitRequestAction {
  type: ActionTypes.REQUEST;
}

interface LoadAction {
  type: ActionTypes.LOAD;
  data: {
    currentFolder: any;
    folders: Immutable.List<any>;
    photos: Immutable.List<any>;
  };
}

interface MoveAction {
  type: ActionTypes.MOVE_ITEM;
  movedItem: {
    type: DragTypes;
    id: number;
  };
}

interface DeleteAction {
  type: ActionTypes.DELETE_ITEM;
  deletedItem: number;
}

interface ReorderAction {
  type: ActionTypes.REORDER_ITEM;
  fromID: number;
  toID: number;
}

interface CreateFolderAction {
  type: ActionTypes.CREATE_FOLDER;
  folder: Folder;
}

interface RenameFolderAction {
  type: ActionTypes.RENAME_FOLDER;
  id: number;
  name: string;
}

interface DeleteFolderAction {
  type: ActionTypes.DELETE_FOLDER;
  id: number;
}

interface AddUploadedItemAction {
  type: ActionTypes.ADD_ITEM;
  item: Media;
}

type Action =
  | LoadAction
  | MoveAction
  | DeleteAction
  | InitRequestAction
  | CreateFolderAction
  | RenameFolderAction
  | DeleteFolderAction
  | AddUploadedItemAction
  | ReorderAction;

interface Item {
  id: number;
}

interface Movable extends Item {
  isMoved: boolean;
}

interface Deletable extends Item {
  isDeleted: boolean;
}

interface Folder extends Movable {
  name: string;
}

interface Media extends Movable, Deletable {
  mime: string;
  resizedPath: string;
  thumbnailPath: string;
}

interface State {
  isLoading: boolean;
  currentFolder: Folder | any;
  folders: Immutable.List<Folder>;
  photos: Immutable.List<Media>;
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionTypes.REQUEST:
      if (state.isLoading) {
        throw new Error('Cannot initiate new request while busy');
      }
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_FOLDER: {
      return {
        ...state,
        folders: state.folders.push(action.folder),
      };
    }
    case ActionTypes.LOAD: {
      return {
        currentFolder: action.data.currentFolder,
        folders: Immutable.List(
          action.data.folders.map(
            (item): Folder => ({
              id: item.entity_id,
              name: item.name,
              isMoved: false,
            })
          )
        ),
        photos: Immutable.List(action.data.photos),
        isLoading: false,
      };
    }
    case ActionTypes.MOVE_ITEM: {
      const { movedItem } = action;
      if (movedItem.type === DragTypes.FOLDER) {
        const { folders } = state;
        const index = folders.findIndex((f) => f.id === movedItem.id);
        // assert index is not -1
        if (index === -1) {
          throw new Error('Moved item should exist');
        }
        return {
          ...state,
          isLoading: false,
          folders: folders.remove(index),
        };
      } else {
        const { photos } = state;
        const index = photos.findIndex((p) => p.id === movedItem.id);
        if (index === -1) {
          throw new Error('Moved media should exist');
        }
        return {
          ...state,
          isLoading: false,
          photos: photos.remove(index),
        };
      }
    }
    case ActionTypes.DELETE_ITEM: {
      const { photos } = state;
      const index = photos.findIndex(
        (media) => media.id === action.deletedItem
      );
      if (index === -1) {
        throw new Error('Deleted media should exist');
      }
      return {
        ...state,
        photos: photos.remove(index),
        isLoading: false,
      };
    }
    case ActionTypes.REORDER_ITEM: {
      const { currentFolder, photos } = state;
      const { fromID, toID } = action;
      const fromIndex = photos.findIndex((p) => p.id === fromID);
      const toIndex = photos.findIndex((p) => p.id === toID);
      let ordered = photos;
      if (fromIndex > toIndex) {
        ordered = ordered
          .insert(toIndex, photos.get(fromIndex)!)
          .remove(fromIndex + 1);
      } else {
        ordered = ordered
          .insert(toIndex + 1, photos.get(fromIndex)!)
          .remove(fromIndex);
      }
      const orderedIDs = ordered.map((item) => item.id).toJS();
      Gallery.reorder(currentFolder != null ? currentFolder.id : 0, orderedIDs);
      return {
        ...state,
        photos: ordered,
      };
    }
    case ActionTypes.RENAME_FOLDER: {
      const { id, name } = action;
      const { folders } = state;
      const index = folders.findIndex((f) => f.id === id);
      return {
        ...state,
        folders: folders.set(index, {
          ...folders.get(index)!,
          name,
        }),
      };
    }
    case ActionTypes.DELETE_FOLDER: {
      const { id } = action;
      const { folders } = state;
      const index = folders.findIndex((f) => f.id === id);
      return {
        ...state,
        folders: folders.remove(index),
      };
    }
    case ActionTypes.ADD_ITEM: {
      const {item} = action;
      return {
        ...state,
        photos: state.photos.unshift(item),
      }
    }
  }
}

export default function AdminBrowser(props: { root: number }) {
  const { root } = props;
  const [folder, setFolder] = useState(root);
  const [state, dispatch] = useReducer(reducer, {
    currentFolder: 0,
    isLoading: false,
    folders: Immutable.List([]),
    photos: Immutable.List([]),
  });
  const { user } = useUser();
  const isAdmin = user && user.isAdmin;
  // TODO maybe set these as constants
  const [shown, setShown] = useState(12);
  useEffect(
    () => {
      dispatch({ type: ActionTypes.REQUEST });
      Gallery.getPhotos(folder).then((data) => {
        dispatch({
          type: ActionTypes.LOAD,
          data,
        });
      });
    },
    [folder]
  );

  // set up SSE listener to listen for newly added photos
  useEffect(
    () => {
      if(!isAdmin){
        // no op if user is not admin
        return;
      }
      const sse = getEventSource(`/gallery/init_browser_sse/dummy`);
      sse.addEventListener('TODO_SET_EVENT_NAME', event => {
        const data = parseSSEEvent(event);
        dispatch({
          type: ActionTypes.ADD_ITEM,
          item: data.photo,
        });
      });
      return () => {
        sse.close();
      }
    }, [isAdmin]
  )

  const { currentFolder, isLoading, folders, photos } = state;
  return (
    <>
      {isAdmin && (
        <>
          <Uploader
            endpoint={'/gallery/upload_v2'}
            prepData={fd => {
              fd.set('parent', folder.toString());
            }}
            options={{
              removePreviewOnSuccess: true,
            }}
          />
          <FolderCreator dispatch={dispatch} parent={folder} />
        </>
      )}
      <DndProvider backend={HTML5Backend}>
        <div className={styles.dndRoot}>
          {isLoading && (
            <div className={styles.loading}>
              <Loading />
            </div>
          )}
          <div className={styles.folders}>
            {folder !== root &&
              currentFolder != null && (
                <FolderItem
                  dispatch={dispatch}
                  folderID={currentFolder.parent}
                  folderName="뒤로"
                  openFolder={setFolder}
                  isParentLink={true}
                />
              )}
            {folders.map((folder) => (
              <FolderItem
                key={folder.id}
                dispatch={dispatch}
                folderID={folder.id}
                folderName={folder.name}
                openFolder={setFolder}
                isParentLink={false}
              />
            ))}
          </div>
          <PhotoList dispatch={dispatch} photos={photos.slice(0, shown)} />
          {shown < photos.size && (
            <Button onClick={() => setShown(shown + 6)}>더보기</Button>
          )}
        </div>
      </DndProvider>
    </>
  );
}

function FolderItem({
  dispatch,
  folderID,
  folderName,
  openFolder,
  isParentLink,
}: {
  dispatch: Dispatch<Action>;
  folderID: number;
  folderName: string;
  openFolder(folder: number): void;
  isParentLink: boolean;
}) {
  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isAdmin = useIsAdmin();
  const [{ isOver }, dropRef] = useDrop({
    accept: [DragTypes.FOLDER, DragTypes.MEDIA],
    canDrop: (item) => {
      return item.id !== folderID;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.canDrop(),
    }),
    drop: (item: { type: DragTypes; id: number }) => {
      dispatch({
        type: ActionTypes.REQUEST,
      });
      const { id: movedID, type } = item;
      if (type === DragTypes.FOLDER) {
        Folder.move(movedID, folderID).then(() => {
          dispatch({
            type: ActionTypes.MOVE_ITEM,
            movedItem: {
              id: movedID,
              type: DragTypes.FOLDER,
            },
          });
        });
      } else if (type === DragTypes.MEDIA) {
        Gallery.moveItem(movedID, folderID).then(() => {
          dispatch({
            type: ActionTypes.MOVE_ITEM,
            movedItem: {
              id: movedID,
              type: DragTypes.MEDIA,
            },
          });
        });
      }
    },
  });

  async function onDelete() {
    setIsSubmitting(true);
    try {
      await Folder.remove(folderID);
      dispatch({
        type: ActionTypes.DELETE_FOLDER,
        id: folderID,
      });
      setShowDeleteModal(false);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function onRename() {
    setIsSubmitting(true);
    try {
      await Folder.rename(folderID, newFolderName);
      dispatch({
        type: ActionTypes.RENAME_FOLDER,
        id: folderID,
        name: newFolderName,
      });
      setShowEditNameModal(false);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className={styles.item}>
      <DraggableMedia id={folderID} type={DragTypes.FOLDER}>
        <div
          ref={dropRef}
          className={joinClasses(styles.folder, isOver && styles.hovered)}
          onClick={() => openFolder(folderID)}>
          {isOver ? <strong>{folderName}</strong> : folderName}
          {isAdmin &&
            !isParentLink && (
              <div
                className={styles.folderHoverMenu}
                onClick={(ev) => ev.stopPropagation()}>
                <ul>
                  <li onClick={() => setShowDeleteModal(true)}>삭제</li>
                  <li
                    onClick={() => {
                      setNewFolderName(folderName);
                      setShowEditNameModal(true);
                    }}>
                    편집
                  </li>
                </ul>
              </div>
            )}
        </div>
      </DraggableMedia>
      {showDeleteModal && (
        <Modal
          footer={
            <>
              <Button
                buttonType="return"
                onClick={() => setShowDeleteModal(false)}>
                취소
              </Button>
              <Button buttonType="submit" onClick={onDelete}>
                삭제
              </Button>
            </>
          }
          isBusy={isSubmitting}
          isShown={true}
          title="정말로 삭제하시겠습니까?">
          폴더 이름: <strong>{folderName}</strong>
        </Modal>
      )}
      {showEditNameModal && (
        <Modal
          footer={
            <>
              <Button
                buttonType="return"
                onClick={() => setShowEditNameModal(false)}>
                취소
              </Button>
              <Button buttonType="submit" onClick={onRename}>
                확인
              </Button>
            </>
          }
          isBusy={isSubmitting}
          isShown={true}
          title="폴더 이름 변경">
          새 이름: <TextBox value={newFolderName} onChange={setNewFolderName} />
        </Modal>
      )}
    </div>
  );
}

const PhotoList = ({
  dispatch,
  photos,
}: {
  dispatch: Dispatch<Action>;
  photos: Immutable.List<Media>;
}) => {
  const { user } = useUser();
  return (
    <div>
      {photos.map((photo) => {
        const { mime } = photo;
        let body: React.ReactNode = 'Unimplemented';
        if (/image/.test(mime)) {
          body = (
            <GalleryImage
              expandedPath={photo.resizedPath}
              id={photo.id}
              isEditable={true}
              onDelete={
                // TODO implement
                () => {
                  Gallery.deleteImage(photo.id).then(() =>
                    dispatch({
                      type: ActionTypes.DELETE_ITEM,
                      deletedItem: photo.id,
                    })
                  );
                }
              }
              relativePath={photo.thumbnailPath}
            />
          );
        } else if (/video/.test(mime)) {
          body = <MGVideo video={photo as any} />;
        }
        if (user && user.isAdmin) {
          return (
            <ReorderableTarget dispatch={dispatch} id={photo.id} key={photo.id}>
              <DraggableMedia id={photo.id} type={DragTypes.MEDIA}>
                <div className={styles.item}>{body}</div>
              </DraggableMedia>
            </ReorderableTarget>
          );
        }
        return (
          <div className={styles.item} key={photo.id}>
            {body}
          </div>
        );
      })}
    </div>
  );
};

function DraggableMedia({
  children,
  id,
  type,
}: {
  children: React.ReactNode;
  id: number;
  type: DragTypes;
}) {
  const [, dragRef] = useDrag({
    item: { type, id: id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return <span ref={dragRef}>{children}</span>;
}

function ReorderableTarget({
  children,
  dispatch,
  id,
}: {
  children: React.ReactNode;
  dispatch: Dispatch<Action>;
  id: number;
}) {
  const [, dropRef] = useDrop({
    accept: [DragTypes.MEDIA],
    drop: (item: { type: DragTypes; id: number }) => {
      dispatch({
        type: ActionTypes.REORDER_ITEM,
        fromID: item.id,
        toID: id,
      });
    },
  });
  return <span ref={dropRef}>{children}</span>;
}

function FolderCreator(props: { dispatch: Dispatch<Action>; parent: number }) {
  const [folderName, setFolderName] = useState('');
  const [error, setError] = useState<null | Error>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const genCreateFolder = async () => {
    setIsSubmitting(true);
    setError(null);
    try {
      const results = await Folder.create(props.parent, folderName);
      props.dispatch({
        type: ActionTypes.CREATE_FOLDER,
        folder: {
          isMoved: false,
          id: results.folder.id,
          name: results.folder.name,
        },
      });
      setFolderName('');
    } catch (e) {
      setError(e);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className={BrowserStyles.createFolder}>
      <TextBox value={folderName} onChange={setFolderName} />
      <Button
        busy={isSubmitting}
        buttonType="create"
        disabled={!folderName}
        onClick={genCreateFolder}>
        폴더
      </Button>
      <UserErrorMessage error={error || undefined} />
    </div>
  );
}
