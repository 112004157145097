import * as React from 'react';

import {joinClasses} from 'Utility';
import Alert, { AlertType, UserErrorMessage } from './Alerts';
import FormThemeContext, { FormTheme } from './FormTheme.context';
import './Form.css';

interface Props {
  children: React.ReactNode;
  onSubmit: () => Promise<void>;

  name?: string;
  
  theme?: FormTheme;
  title?: string;
  successEl?: React.ReactNode;
  validate?: () => void;
}

interface State {
  error?: Error;
  isBusy: boolean;
  success: boolean;
}

export class Form extends React.Component<Props, State> {
  state: State = {
    isBusy: false,
    success: false,
  };

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    const { title, children, name, successEl, theme } = this.props;
    const { error, isBusy, success } = this.state;

    const titleEl = title && <h1 className="mg-form-title">{title}</h1>;
    if (success) {
      return (
        successEl || (
          <Alert type={AlertType.INFO} message="성공적으로 처리되었습니다." />
        )
      );
    }

    return (
      <form
        onSubmit={this._onSubmit}
        className="mg-form"
        id={name && `mg-form-${name}`}>
        <FormContext.Provider value={{ isBusy }}>
          {titleEl}
          {theme != null ? (
            <FormThemeContext.Provider value={theme}>
              {children}
            </FormThemeContext.Provider>
          ) : (
            children
          )}
        </FormContext.Provider>
        <UserErrorMessage error={error} />
      </form>
    );
  }

  _onSubmit = async (event: React.FormEvent) => {
    // clear errors
    this.setState({ error: undefined });
    const { onSubmit, validate } = this.props;
    try {
      event.preventDefault();
      validate && validate();
      this.setState({
        isBusy: true,
      });
      await onSubmit();
      this.setState({
        success: true,
      });
    } catch (error) {
      this.setState({
        error,
      });
    } finally {
      this.setState({
        isBusy: false,
      });
    }
  };
}

interface FormRowProps {
  children: React.ReactNode;
  label?: string;
}

export function FormRow(props: FormRowProps) {
  const { label, children } = props;
  const theme = React.useContext(FormThemeContext);
  return (
    <div
      className={joinClasses(
        'mg-form-row',
        theme.align === 'left' && 'mg-align-left'
      )}>
      <div className="mg-form-label" style={{ width: theme.labelWidth }}>
        {label}
      </div>
      <div className="mg-form-content">{children}</div>
    </div>
  );
}

interface FormContext {
  isBusy: boolean;
}

export const FormContext = React.createContext<FormContext>({
  isBusy: false,
});

export const useIsBusy = () => React.useContext(FormContext).isBusy;
