import * as React from 'react';
import Loading from '../../Base/Loading';
import { UserErrorMessage } from '../Alerts';
import MarkdownContent from '../MarkdownContent';
import { Page } from '../../API';
import { nullthrows } from '../../Utility';
import { AdminBar, ToolbarItem } from '../Toolbar';
import { LinkButton } from '../Button';

class Viewer extends React.Component {
  state = {
    page: null,
    error: null,
    ready: false,
  };

  async componentDidMount() {
    try {
      const results = await Page.get(this.props.pageKey);
      if (!results.success || !results.data) {
        throw new Error(
          results.error || '페이지 컨텐츠를 불러올수 없었습니다.'
        );
      }
      this.setState({ page: results.data });
    } catch (error) {
      this.setState({
        error,
      });
    } finally {
      this.setState({
        ready: true,
      });
    }
  }

  render() {
    const { match } = this.props;
    const { page, error, ready } = this.state;

    if (!ready) {
      return <Loading />;
    }
    if (error) {
      return <UserErrorMessage message={error.message} />;
    }
    return (
      <>
        <AdminBar>
          <ToolbarItem>
            <LinkButton to={match.url + '/edit'}>편집</LinkButton>
          </ToolbarItem>
        </AdminBar>
        <MarkdownContent content={nullthrows(page).content} />
      </>
    );
  }
}

export default Viewer;
