import React, { Component } from 'react';
import { Form, FormRow } from '../../Common/Form';
import { Button } from '../../Common/Button.js';
import { User } from '../../API';

export default class FindPW extends Component {
  state = {
    email: '',
    message: null,
  };

  updateEmail = (ev) => {
    this.setState({ email: ev.target.value });
  };

  onSubmit = async () => {
    const results = await User.requestResetPassword(this.state.email);
    if (!results.success) {
      throw new Error(
        results.error || '이메일 비밀번호 변경요청에 실패했습니다.'
      );
    }
  };

  render() {
    return (
      <Form
        title="비밀번호 찾기"
        onSubmit={this.onSubmit}
        successEl="이메일로 비밀번호 변경정보를 보냈습니다. 이메일계정을 확인해주세요"
      >
        <FormRow label="이메일">
          <input
            type="email"
            value={this.state.email}
            onChange={this.updateEmail}
          />
        </FormRow>
        <FormRow>
          <Button buttonType="submit" disabled={!this.state.email}>
            확인
          </Button>
          <p>{this.state.message}</p>
        </FormRow>
      </Form>
    );
  }
}
