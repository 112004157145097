import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
  Home,
  About,
  Programs,
  Board,
  Blog,
  Gallery,
  Calendar,
  Member,
  Info,
} from './Pages';
import { Navigation, Footer } from './Base';
import { getUserInfo, tryLogout } from './API';
import UserContext from './Utility/User';
import PopupImageContext from './Utility/PopupImageContext';
import PopupImageViewer from './Common/PopupImageViewer';
import NotificationBank from './Utility/NotificationBank';
import Loading from './Base/Loading';
import Camp from './Pages/Camp';
import BlogTabNav from './Pages/Blog/BrowserTagNav';

import 'file-icon-vectors/dist/file-icon-vivid.min.css';
import './App.css';
import layout from './Layout.module.css';

class App extends Component {
  state = {
    ready: false,
    user: null,
    popupImageSrc: null,
  };

  setUser = (user) => {
    this.setState({ user });
  };
  
  logoutUser = async () => {
    this.setState({ ready: false });
    try {
      const result = await tryLogout();
      if (result.success) {
        this.setState({ user: null });
      } else {
        throw new Error('로그아웃에 실패했습니다. 페이지를 새로고쳐주세요!');
      }
    } finally {
      this.setState({ ready: true });
    }
  };

  async componentDidMount() {
    try {
      const user = await getUserInfo();
      if (user.id > 0) {
        this.setState({ user });
      }
    }
    catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Failed to load user information:', error);
      }
    }
    finally {
      this.setState({ ready: true });
    }
  }

  render() {
    const { user, ready, popupImageSrc } = this.state;
    if (!ready) {
      return <Loading />;
    }
    return (
      <UserContext.Provider
        value={{
          user,
          setUser: this.setUser,
          logoutUser: this.logoutUser,
        }}>
        <PopupImageContext.Provider
          value={{
            showImage: this._setPopupImage,
          }}>
          <NotificationBank>
            <BrowserRouter>
              <div className={layout.root}>
                <Navigation />
                <div className={layout.bodyContainer}>
                  <div className={layout.leftBar}>
                    <Route path="/blog" exact component={BlogTabNav} />
                  </div>
                  <div className={layout.body}>
                    <Switch>
                      <Route exact path="/" component={Home} />
                      <Route path="/about" component={About} />
                      <Route path="/programs" component={Programs} />
                      <Route path="/board" component={Board} />
                      <Route path="/blog" component={Blog} />
                      <Route path="/info" component={Info} />
                      <Route path="/gallery" component={Gallery} />
                      <Route path="/calendar" component={Calendar} />
                      <Route path="/member" component={Member} />
                      <Route path="/camp" component={Camp} />
                      <Route component={NotFoundError} />
                    </Switch>
                  </div>
                  <div className={layout.rightBar}>
                    {/* empty for now */}
                  </div>
                </div>
                <Footer />
              </div>
            </BrowserRouter>
          </NotificationBank>
          <PopupImageViewer closePopup={this._closePopup} src={popupImageSrc} />
        </PopupImageContext.Provider>
      </UserContext.Provider>
    );
  }

  _setPopupImage = (src) => {
    this.setState({
      popupImageSrc: src,
    });
  };

  _closePopup = () => {
    this.setState({
      popupImageSrc: null,
    });
  };
}

const NotFoundError = () => (
  <div className={layout.error404}>존재하지 않는 페이지입니다.</div>
);

export default App;
