import React from 'react';
import { Switch, Route } from 'react-router-dom';
import EventViewer from './EventViewer';
import EventCreator from './EventCreator';
import EventEditor from './EventEditor';
import Calendar from './Calendar';

export default ({ match }) => {
  const { url } = match;
  return (
    <Switch>
      <Route path={`${url}/event/edit/:event`} component={EventEditor} />
      <Route path={`${url}/event/:event`} component={EventViewer} />
      <Route path={`${url}/create`} component={EventCreator} />
      <Route path={`${url}/schedule/:year/:month`} component={Calendar} />
      <Route path={url} component={Calendar} />
    </Switch>
  );
};
