import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Editor from './Editor';
import Viewer from './Viewer';

const EditablePage = (props) => {
  const { match, pageKey } = props;
  return (
    <Switch key={pageKey}>
      <Route
        path={match.url + '/edit'}
        exact
        render={({ match: subMatch }) => (
          <Editor match={subMatch} pageKey={pageKey} returnURL={match.url} />
        )}
      />
      <Route
        path={match.url}
        render={({ match }) => <Viewer match={match} pageKey={pageKey} />}
      />
    </Switch>
  );
};

export default EditablePage;
