import React from 'react';

import { Button, LinkButton } from '../../Common/Button';
import { Blog } from '../../API';
import { AdminBar, ToolbarItem } from '../../Common/Toolbar';
import { PageSpinner } from '../../Base';
import PaginationBrowser from '../../Utility/PaginationBrowser';

import './Browser.css';
import { getQueryParameterAsNumber } from './BlogUtil';

class Browser extends React.Component {
  fetchPostCount = async () => {
    return Blog.getPostCount(this._getFilterTag());
  }

  fetchPosts = async (page) => {
    return Blog.getBlog(page, this._getFilterTag());
  }

  _getFilterTag() {
    return getQueryParameterAsNumber(this.props.location.search, 'tag');
  }

  render() {
    const { match, location, history } = this.props;
    return (
      <div className="blog-browser">
        <AdminBar>
          <ToolbarItem>
            <LinkButton buttonType="create" to={`${match.url}/new`}>
              새 글
          </LinkButton>
          </ToolbarItem>
        </AdminBar>
        <PaginationBrowser
          loadCount={this.fetchPostCount}
          loadData={this.fetchPosts}
          location={location}
          history={history}
          categoryHash={this._getFilterTag()}
          pageSize={5}
          spinner={<PageSpinner />}
          render={(blogItems, reload) => {
            return (
              <>
                {blogItems.map((post, i) => (
                  <article className="post" key={i}>
                    <section className="blog-header">
                      <h1>{post.title}</h1>
                    </section>
                    <section
                      className="blog-content"
                      dangerouslySetInnerHTML={{ __html: post.content }}
                    />
                    <LinkButton to={`${match.url}/post/${post.id}`}>
                      더보기
                    </LinkButton>
                    <AdminBar>
                      <Button onClick={async () => {
                        await Blog.deletePost(post.id);
                        reload();
                      }}>Delete</Button>
                    </AdminBar>
                  </article>
                ))}
              </>
            );
          }}
        />
      </div>
    );
  }
}

export default Browser;
