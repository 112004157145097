import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Hello from './Hello';
import Intro from './Intro';
import People from './People';

class About extends React.Component{
    render(){
        const {match} = this.props;
        
        return (
            <Switch>
                <Route path={match.url + '/hello'} component={Hello} />
                <Route path={match.url + '/intro'} component={Intro} />
                <Route path={match.url + '/people'} component={People} />
            </Switch>
        )
    }
}

export default About;

