import { requireAdmin } from '../../Utility/User';
import React from 'react';
import { Form, FormRow } from '../../Common/Form';
import { getEventDetails, Event as EventAPI } from '../../API';
import TextBox from '../../Common/TextBox';
import TextArea from '../../Common/TextArea';
import { Button, LinkButton } from '../../Common/Button';
import { Loading } from '../../Base';
import { UserErrorMessage } from '../../Common/Alerts';
import { nullthrows, toIDx } from '../../Utility';

class EventEditor extends React.Component {
  state = {
    error: null,
    event: null,
    ready: false,
  };

  async componentDidMount() {
    const { match } = this.props;
    try {
      const results = await getEventDetails(toIDx(match.params.event));
      this.setState({
        ready: true,
        event: results.event,
      });
    } catch (e) {
      this.setState({ error: e });
    }
  }

  render() {
    const { error, ready, event } = this.state;

    if (!ready) return <Loading />;
    if (error) {
      return <UserErrorMessage error={error} />;
    }
    if (!event) {
      return <div>이벤트 정보를 불러오지못했습니다.</div>;
    }
    const { id, title, eventTime, description } = event;
    return (
      <React.Fragment>
        <Form title="이벤트 수정하기" onSubmit={this._onSubmit}>
          <FormRow label="이벤트 제목">
            <TextBox onChange={this._updateTitle} value={title} />
          </FormRow>
          <FormRow label="이벤트 내용">
            <TextArea onChange={this._updateDescription} value={description} />
          </FormRow>
          <FormRow>
            <Button disabled={!title.length || !eventTime} buttonType="submit">
              확인
            </Button>
            <LinkButton to={`/calendar/event/${id}`}>취소</LinkButton>
          </FormRow>
        </Form>
      </React.Fragment>
    );
  }

  _onSubmit = async () => {
    const { id, title, eventTime, description } = nullthrows(this.state.event);
    const results = await EventAPI.updateEvent({
      id,
      title,
      eventTime,
      description,
    });
    if (!results.success) {
      throw new Error('이벤트 정보를 수정하지 못했습니다.');
    }
  };

  _updateTime = (eventTime) => {
    this.setState((prevState) => ({
      event: {
        ...prevState.event,
        eventTime,
      },
    }));
  };

  _updateTitle = (title) => {
    this.setState((prevState) => ({
      event: {
        ...prevState.event,
        title,
      },
    }));
  };

  _updateDescription = (description) => {
    this.setState((prevState) => ({
      event: {
        ...prevState.event,
        description,
      },
    }));
  };
}

export default requireAdmin(EventEditor);
