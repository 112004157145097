import * as React from 'react';
import './Table.css';

interface TableProps {
  cols: Array<string>;
  labels: { [columnKey: string]: string };
  rows: Array<{ [columnKey: string]: string }>;
}

export default function({ cols, labels, rows }: TableProps) {
  return (
    <table className="mg-table">
      <thead>
        <tr>
          {cols.map((col, i) => (
            <th key={i}>
              {labels[col]}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rid) => (
          <tr className={rid % 2 ? 'odd' : 'even'} key={rid}>
            {cols.map((col: string, i: number) => (
              <td key={i}>{row[col]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

interface TableV2Props<Row>{
  cols: Array<{
    renderer(row: Row): React.ReactNode;
    name: string;
  }>;
  rows: Array<Row>;
}

export function TableV2<Row>(props: TableV2Props<Row>){
  const {cols, rows} = props;
  return (
    <table className="mg-table">
      <thead>
        <tr>
          {cols.map((col, i) => (
            <th key={i}>
              {col.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rid) => (
          <tr className={rid % 2 ? 'odd' : 'even'} key={rid}>
            {cols.map((col, i: number) => (
              <td key={i}>{col.renderer(row)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}