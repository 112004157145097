import React from 'react';

import { User } from 'API';
import { Button } from 'Common/Button';
import { Form, FormRow } from 'Common/Form';
import TextBox from 'Common/TextBox';
import { nullthrows } from 'Utility';
import { useUser } from 'Utility/User';

function SetNameForm(props) {
  const [newName, setNewName] = React.useState('');
  const userContext = useUser();
  const onSubmit = React.useCallback(
    async function() {
      const results = await User.changeName(newName);
      if (!results.success) {
        throw new Error('성공적으로 처리하지 못했습니다.');
      }
    },
    [newName]
  );

  return (
    <Form
      onSubmit={onSubmit}
      successEl="이름을 성공적으로 변경했습니다. 만약 새 이름으로 표시되지않는다면 페이지를 새로고쳐주세요!"
      title="이름 변경하기">
      <FormRow label="현재 이름">
        {nullthrows(userContext.user, 'User should never be null').name}
      </FormRow>
      <FormRow label="새 이름">
        <TextBox value={newName} onChange={setNewName} />
      </FormRow>
      <FormRow>
        <Button buttonType="submit">확인</Button>
      </FormRow>
    </Form>
  );
}

export default SetNameForm;
