import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import Styles from './Camp.module.css';
import CAMP_INFO_BROCHURE_2019 from './camp_photo_full.jpg';
import CAMP_INFO_BROCHURE_2023 from './resized_camp_2023.jpg';

export default function CampRouter() {
  return (
    <Switch>
      <Route path="/camp/2019" component={Camp2019Home} />
      <Route path="/camp/2023" component={Camp2023Home} />
    </Switch>
  );
}

function Camp2019Home() {
  return <div>
    <img alt="캠프 정보" src={CAMP_INFO_BROCHURE_2019} className={Styles.brochure} />

    <div className={Styles.video1}>
      <h1>강사 워크샵</h1>
      <iframe
        className={Styles.embeddedVideo}
        src="https://www.youtube.com/embed/8XHw67l2Jrg"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen />
    </div>

    <div className={Styles.video2}>
      <h1>하일라이트 (단편)</h1>
      <iframe
        className={Styles.embeddedVideo}
        src="https://www.youtube.com/embed/iKACer3uhss"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen />
    </div>

    <div className={Styles.video3}>
      <h1>하일라이트 (장편)</h1>
      <iframe
        className={Styles.embeddedVideo}
        src="https://www.youtube.com/embed/KkD9sfBSoYQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen />
    </div>

    <div className={Styles.video4}>
      <h1>캠프 1일</h1>
      <iframe
        className={Styles.embeddedVideo}
        src="https://www.youtube.com/embed/2iGYD5Aa5-M"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen />
    </div>


    <div className={Styles.video5}>
      <h1>캠프 2일</h1>
      <iframe
        className={Styles.embeddedVideo}
        src="https://www.youtube.com/embed/LZzNcUzTOj8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen />
    </div>


    <div className={Styles.video}>
      <h1>캠프 3일</h1>
      <iframe
        className={Styles.embeddedVideo}
        src="https://www.youtube.com/embed/LeK8_GiBq48"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen />
    </div>
  </div>;
}

function Camp2023Home() {
  return <div>
    <img alt="캠프 정보" src={CAMP_INFO_BROCHURE_2023} className={Styles.brochure} />
  </div>
}
