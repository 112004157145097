import * as React from 'react';

export interface FormTheme{
    align: 'left' | 'right',
    labelWidth: string,
}

const DEFAULT_THEME: FormTheme = {
    align: 'right',
    labelWidth: '8rem',
}

export default React.createContext(DEFAULT_THEME);