import React from 'react';
import { Redirect } from 'react-router-dom';

import { Loading } from 'Base';
import { useUser } from 'Utility/User';
import { NotificationContext } from 'Utility/Notification';

function Logout(props) {
  const [completed, setCompleted] = React.useState(false);
  const userContext = useUser();
  const {addNotification} = React.useContext(NotificationContext);

  React.useEffect(
    function() {
      if (userContext.user) {
        userContext.logoutUser().then(function() {
          setCompleted(true);
        });
      } else {
        setCompleted(true);
      }
    },
    [userContext, addNotification]
  );

  if (!completed) {
    return <Loading />;
  }
  if (userContext.user) {
    throw new Error('Logout process did not clear user settings.');
  } else {
    addNotification({
      type: 'success',
      message: '로그아웃 하셨습니다.',
    })
    return <Redirect to="/" />;
  }
}

export default Logout;
