import React from 'react';
import styles from './Intro.module.css';

import SLIDE1 from './intro_images/Slide1.PNG';
import SLIDE2 from './intro_images/Slide2.PNG';
import SLIDE3 from './intro_images/Slide3.PNG';
import SLIDE4 from './intro_images/Slide4.PNG';
import SLIDE5 from './intro_images/Slide5.PNG';
import SLIDE6 from './intro_images/Slide6.PNG';
import SLIDE7 from './intro_images/Slide7.PNG';
import SLIDE8 from './intro_images/Slide8.PNG';
import SLIDE9 from './intro_images/Slide9.PNG';
import SLIDE10 from './intro_images/Slide10.PNG';
import SLIDE11 from './intro_images/Slide11.PNG';

function Intro() {
  return <div className={styles.root}>
    <img src={SLIDE1} />
    <img src={SLIDE2} />
    <img src={SLIDE3} />
    <img src={SLIDE4} />
    <img src={SLIDE5} />
    <img src={SLIDE6} />
    <img src={SLIDE7} />
    <img src={SLIDE8} />
    <img src={SLIDE9} />
    <img src={SLIDE10} />
    <img src={SLIDE11} />
  </div>
}

export default Intro;
