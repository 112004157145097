import * as React from 'react';

import Tag from '../Base/Types/Tag';

import TagStyles from './TagEditor.module.css';

export default function TagsDisplay(props: {
  tags: ReadonlyArray<Tag>,
}) {
  const { tags } = props;
  if (tags.length === 0) {
    return null;
  }
  return (<div className={TagStyles.tagViewer}>
    <span className={TagStyles.tagViewerLabel}>관련 태그</span>
    {tags.map(tag =>
      <span className={TagStyles.activeTag} key={tag.id}>{tag.name}</span>
    )}
  </div>);
}