import * as React from 'react';
import { List } from 'immutable';

import Photo from '../../Base/Types/S3File';
import { Gallery } from '../../API';
import { Button } from '../../Common/Button';
import { requireAdmin } from '../../Utility/User';
import Query from '../../Utility/Query';

import SlideshowStrip from './SlideshowStrip';
import SlideshowPhotoPicker from './SlideshowPhotoPicker';
import './SlideshowEditor.css';

// Handles querying - actual logic in editorImpl
function EditorWrapper() {
  return (
    <Query
      loadData={fetchSlideshowData}
      render={([images, slides]: Array<any>) =>
        images.success && slides.success ? (
          <Editor images={images.data} savedSlides={slides.data} />
        ) : null
      }
    />
  );
}

async function fetchSlideshowData() {
  return Promise.all([Gallery.fetchAllImages(), Gallery.fetchActiveSlides()]);
}

export default requireAdmin(EditorWrapper);

interface EditorProps {
  images: Array<Photo>;
  savedSlides: Array<Photo>;
}

interface EditorState {
  slides: List<Photo>;
  isWorking: boolean;
}

class Editor extends React.Component<EditorProps, EditorState> {
  state = {
    slides: List(this.props.savedSlides),
    isWorking: false,
  };

  render() {
    const { isWorking, slides } = this.state;
    const { images } = this.props;
    return (
      <div className="slideshow_editor">
        <h1>홈페이지 이미지 설정</h1>
        <div className="slideshow_editor_strip">
          <SlideshowStrip
            onReorder={this._reorderSlide}
            onRemove={this._removeSlide}
            slides={slides}
          />
        </div>
        <div className="slideshow_editor_save">
          <Button busy={isWorking} buttonType="submit" onClick={this._onSave}>
            저장
          </Button>
        </div>
        <div className="slideshow_editor_photopicker">
          <SlideshowPhotoPicker
            images={images}
            isBusy={isWorking}
            onAdd={this._addPhoto}
            addedSlides={slides}
          />
        </div>
      </div>
    );
  }

  _addPhoto = (img: Photo): void => {
    this.setState((state) => ({
      slides: state.slides.push(img),
    }));
  };

  _removeSlide = (index: number): void => {
    this.setState((state) => ({
      slides: state.slides.remove(index),
    }));
  };

  _reorderSlide = (from: number, to: number): void => {
    if (from === to) {
      // really should log this
      return;
    }
    this.setState((state) => {
      const { slides } = state;
      const movedSlide = slides.get(from)!;
      slides.delete(from);
      return {
        slides: slides.remove(from).insert(to > from ? to - 1 : to, movedSlide),
      };
    });
  };

  _onSave = async () => {
    this.setState({ isWorking: true });
    try {
      await Gallery.saveImages(
        this.state.slides.map((img) => img.id).toArray()
      );
    } finally {
      this.setState({ isWorking: false });
    }
  };
}
