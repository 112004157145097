import React from 'react';
import { Button } from './Button';
import Styles from './PopupImageViewer.module.css';

function PopupImageViewer(props) {
  const { closePopup, src } = props;
  React.useEffect(() => {
    const body = document.querySelector('body');
    if(src != null){
      body.classList.add(Styles.noScroll);
    }
    else{
      body.classList.remove(Styles.noScroll);
    }
  });
  
  if (src == null) return null;
  return (
    <div className={Styles.backdrop} onClick={closePopup}>
      <div className={Styles.viewer}>
        <Button className={Styles.close} onClick={closePopup}>
          &times;
        </Button>
        <img src={src} alt="" />
      </div>
    </div>
  );
}

export default React.memo(PopupImageViewer);
