import * as React from 'react';

import Drawer from './Drawer';
import styles from './ErrorDrawer.module.css';
import { List } from 'immutable';

interface Props {
  errors: List<string>;
}

export default function({ errors }: Props) {
  return (
    <Drawer header={<div className={styles.header}>Errors</div>}>
      <div className={styles.errorList}>
        {errors.map((error, index) => {
          return <div className={styles.item} key={index}>{error}</div>;
        })}
      </div>
    </Drawer>
  );
}
