import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import { UserErrorMessage } from 'Common/Alerts';
import SetPasswordForm from './SetPasswordForm';
import SetNameForm from './SetNameForm';
import { useUser } from 'Utility/User';
import './Options.css';

function OptionsPage(props) {
  const { match } = props;
  const userContext = useUser();
  if (userContext.user == null) {
    return (
      <UserErrorMessage message="이 페이지는 로그인하셔야 보실수있습니다." />
    );
  }
  const prefix = match.url;
  return (
    <div className="options-page">
      <nav className="options-page-nav">
        <h2>설정</h2>
        <div className="options-page-link">
          <Link to={prefix + '/set_password'}>비밀번호 바꾸기</Link>
        </div>
        <div className="options-page-link">
          <Link to={prefix + '/set_name'}>이름 바꾸기</Link>
        </div>
      </nav>
      <div className="options-page-body">
        <Switch>
          <Route
            path={match.url + '/set_password'}
            component={SetPasswordForm}
          />
          <Route path={match.url + '/set_name'} component={SetNameForm} />
          <Route
            component={() => (
              <React.Fragment>메뉴에서 옵션을 선택해주세요!</React.Fragment>
            )}
          />
        </Switch>
      </div>
    </div>
  );
}

export default OptionsPage;
