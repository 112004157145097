import React from 'react';
import { Loading } from '../../Base';
import Modal from '../../Base/Modal';
import { getEventDetails, Event as EventAPI } from '../../API';
import DateDisplay from '../../Utility/DateDisplay';
import { UserErrorMessage } from '../../Common/Alerts';
import { AdminBar, ToolbarItem } from '../../Common/Toolbar';
import { Button, LinkButton } from '../../Common/Button';
import { nullthrows } from '../../Utility';

class EventViewer extends React.Component {
  state = {
    deleteError: null,
    error: null,
    ready: false,
    event: null,
    showDeleteModal: false,
  };

  async componentDidMount() {
    const { match } = this.props;
    try {
      this.setState({
        event: (await getEventDetails(match.params.event)).event,
      });
    } catch (error) {
      this.setState({
        error,
      });
    } finally {
      this.setState({
        ready: true,
      });
    }
  }
  render() {
    const { deleteError, error, ready, event, showDeleteModal } = this.state;

    if (!ready) return <Loading />;
    if (error || !event)
      return <UserErrorMessage message="이벤트 내용을 불러오지 못했습니다." />;
    const { title, eventTime, description } = event;
    return (
      <div className="event-viewer">
        <h1>{title}</h1>
        <AdminBar>
          <ToolbarItem>
            <LinkButton to={`/calendar/event/edit/${event.id}`}>
              편집
            </LinkButton>
          </ToolbarItem>
          <ToolbarItem>
            <Button buttonType="delete" onClick={this._showDeleteModal}>
              이벤트 삭제하기
            </Button>
            <Modal
              isShown={showDeleteModal}
              footer={
                <React.Fragment>
                  <Button buttonType="delete" onClick={this._deletePost}>
                    삭제하기
                  </Button>
                  <Button buttonType="return" onClick={this._cancelDelete}>
                    취소
                  </Button>
                </React.Fragment>
              }>
              정말로 삭제하시겠습니까?
              <UserErrorMessage error={deleteError} />
            </Modal>
          </ToolbarItem>
        </AdminBar>
        <DateDisplay date={eventTime} />
        <p>{description}</p>
      </div>
    );
  }

  _cancelDelete = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  _deletePost = async () => {
    try {
      const results = await EventAPI.deleteEvent(
        nullthrows(this.state.event).id
      );
      if (results.success) {
        this.props.history.push('/calendar/');
      } else {
        throw new Error('이벤트를 삭제하지 못했습니다.');
      }
    } catch (error) {
      this.setState({
        deleteError: error,
      });
    }
  };

  _showDeleteModal = () => {
    this.setState({ showDeleteModal: true });
  };
}

export default EventViewer;
