import React from 'react';

import { List } from 'immutable';

import Tag from '../Base/Types/Tag';

import Styles from './TagEditor.module.css';
import { searchTags } from '../API/Blog';
import TextBox from './TextBox';

interface Props {
  tags: List<Tag>;
  onChange(tags: List<Tag>): void;
}

function TagEditor({ tags, onChange }: Props) {
  const [suggestions, setSuggestions] = React.useState<Tag[]>([]);
  const [showSuggestions, _setShowSuggestions] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const textbox = React.useRef<HTMLInputElement>(null);
  const hideBoxTimer = React.useRef<NodeJS.Timeout | null>(null);
  const exactMatch = suggestions.find(
    (suggestedTag) => suggestedTag.name === query
  );
  const addTag = (newTag: Tag) => {
    onChange(tags.push(newTag));
    setQuery('');
    textbox.current!.focus();
  };
  React.useEffect(
    () => {
      searchTags(query).then(setSuggestions);
    },
    [query, setSuggestions]
  );
  const unusedTags = suggestions.filter(
    (suggestedTag) => !tags.some((tag) => tag.id === suggestedTag.id)
  );
  const hideBoxOnBlur = () => {
    hideBoxTimer.current = setTimeout(
      () => _setShowSuggestions(false), 200
    );
  }
  const setShowBox = (show: boolean) => {
    if(hideBoxTimer.current != null){
      clearTimeout(hideBoxTimer.current);
    }
    _setShowSuggestions(show);
  }
  return (
    <div className={Styles.root}>
      <div className={Styles.appliedTags}>
        {tags.size > 0 ? (
          tags.map((tag, i) => (
            <span className={Styles.activeTag} key={tag.id + tag.name}>
              {tag.name} |
              <span
                className={Styles.removeTagButton}
                onClick={() => onChange(tags.remove(i))}>
                X
              </span>
            </span>
          ))
        ) : (
          <em>적용된 태그가 없습니다.</em>
        )}
      </div>
      <div className={Styles.queryContainer}>
        <TextBox onChange={setQuery} onFocus={() => setShowBox(true)} onBlur={hideBoxOnBlur} ref={textbox} value={query} />
        {(unusedTags.length > 0 || query !== '') && (
          <div className={`${Styles.completionBox} ${!showSuggestions ? Styles.hidden : ''}`}>
            {unusedTags.map((tag, i) => {
              return (
                <div
                  className={Styles.selectable}
                  key={i}
                  onClick={() => addTag(tag)}>
                  {tag.name}
                </div>
              );
            })}
            {query !== '' &&
              exactMatch == null && (
                <>
                  <hr className={Styles.customTagSeparator} />
                  <div
                    className={Styles.customTagOption}
                    onClick={() =>
                      addTag({
                        id: -1,
                        name: query,
                      })
                    }>
                    새 "{query}" 태그 만들기
                  </div>
                </>
              )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TagEditor;
