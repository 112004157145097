import React from 'react';
import { Link } from 'react-router-dom';

import { Blog } from 'API';
import { AdminBar, ToolbarItem } from 'Common/Toolbar';
import { Article, ArticleTitle, ArticleBody } from 'Common/Article';
import { LinkButton } from 'Common/Button.js';
import MarkdownContent from 'Common/MarkdownContent';
import TagsDisplay from 'Common/TagsDisplay';
import Browser from 'Pages/Gallery/Browser';
import DateDisplay from 'Utility/DateDisplay';
import Query from 'Utility/Query';

import Strings from './PostViewer.strings';
import styles from './PostViewer.module.css';

function PostViewer({ match }) {
  return (
    <Query
      key={match.params.post}
      loadData={() => Blog.getPost(match.params.post)}
      render={({
        tags,
        post: blogPost,
        next: nextPost,
        previous: previousPost,
      }) => (
        <Article>
          <div className={styles.timelinks}>
            <div>
              {nextPost && (
                <Link to={String(nextPost.id)}>이전 글: {nextPost.title}</Link>
              )}
            </div>
            <div>
              {previousPost && (
                <Link to={String(previousPost.id)}>
                  지난 글: {previousPost.title}
                </Link>
              )}
            </div>
          </div>
          <ArticleTitle>{blogPost.title}</ArticleTitle>
          <AdminBar>
            <ToolbarItem>
              <LinkButton to={`/blog/edit/${blogPost.id}`}>
                {Strings.EDIT_LABEL}
              </LinkButton>
            </ToolbarItem>
          </AdminBar>
          <div className={styles.dateDisplay}> 
            <DateDisplay date={blogPost.createdOn} />
          </div>
          <TagsDisplay tags={tags} />
          <ArticleBody>
            <MarkdownContent content={blogPost.content} />
            <Browser parent={blogPost.id} />
          </ArticleBody>
        </Article>
      )}
    />
  );
}

export default PostViewer;
