import * as React from 'react';
import { S3 } from 'API';
import styles from './People.module.css';

function getCaricatureURL(path: string): string {
  return S3(`assets/images/people/${path}`);
}

interface Bio {
  image: string;
  name: string;
  title: string;
  field: string;
  education: Array<string>;
  likes: Array<{
    category: string;
    value: string;
  }>;
}

const BIOS: Array<Bio> = [
  {
    image: 'eunjung.jpg',
    name: '손은정',
    title: '이사장/책임강사',
    field: '목공/그린아트/직조',
    education: [
      '이화대학교 철학과 학사',
      'Columbia Teachers college, 특수교육학과 석사',
      'San Francisco 발도로프 치료 교사교육 수료',
    ],
    likes: [
      { category: '색갈', value: '푸른색' },
      { category: '운동', value: '수영, 태극권' },
      { category: '장소', value: '아이들 놀이터, 숲' },
    ],
  },
  {
    image: 'myungsuk.jpg',
    name: '양명석',
    title: '목공 책임강사',
    education: [
      'UC Berkeley, 기계공학 학사 &석사',
      'Yale University, 경제학 박사',
      'Columbia University, 법학박사',
    ],
    field: '목공/그린아트',
    likes: [
      { category: '취미', value: '목공' },
      { category: '음식', value: 'BBQ' },
    ],
  },
  {
    image: 'taeyeon.jpg',
    name: '김태연',
    title: '직조 책임강사',
    field: '직조/그린아트',
    education: [
      '홍익대학교 섬유미술과 학사',
      '홍익대학교 섬유미술과 석사',
      '홍익대학교 섬유미술과 박사',
    ],
    likes: [
      { category: '취미', value: '멍때리기' },
      { category: '색갈', value: '연두' },
      { category: '음식', value: '보글보글 냄비우동' },
    ],
  },
  {
    image: 'kyungmin.jpg',
    name: '박경민',
    education: [
      '인하대학교 미술교육과 학사',
      '인하대학교 조형예술학과 석사'
    ],
    title: '주임강사',
    field: '목공/그린아트',
    likes: [
      { category: '음식', value: '배고플때 먹는 음식' },
      { category: '색갈', value: '보라색' },
      { category: '운동', value: '수영' },
    ],
  },
  {
    image: 'sanghyuk.jpg',
    name: '임상현',
    education: [
      '인하대학교 미술과 학사',
      '인하대학교 조형예술학과 석사',
    ],
    title: '강사',
    field: '목공/그린아트',
    likes: [
      { category: '취미', value: '노래' },
      { category: '색갈', value: '검정' },
      { category: '장소', value: '집' },
    ],
  },
  {
    image: 'donghwa.jpg',
    name: '이동하',
    education: [
      '인하대학교 미술과 학사',
      '인하대학교 조형예술학과 석사',
    ],
    title: '강사',
    field: '목공/그린아트',
    likes: [
      { category: '동물', value: '흰수염 고래' },
      { category: '색갈', value: '녹색' },
      { category: '장소', value: '가보지 못한 아름다운 곳' },
    ],
  },
  {
    image: 'okgyung.jpg',
    name: '심옥경',
    education: [
      '서울수도여자사범대 서양화과 학사',
      '(현, 세종대)',
    ],
    title: '강사',
    field: '직조/그린아트',
    likes: [
      { category: '음식', value: '국수' },
      { category: '색갈', value: '올리브그린' },
      { category: '장소', value: '조용한 산사' },
    ],
  },
  {
    image: 'yoonhee.jpeg',
    education: [
      '가천대학교 회화과 학사',
      '가천대 회화과 석사예정(2020/02)'
    ],
    name: '서윤희',
    title: '강사',
    field: '직조/그린아트',
    likes: [
      { category: '취미', value: '자전거타기' },
      { category: '색갈', value: '파란색, 초록색' },
      { category: '장소', value: '공원, 숲' },
    ],
  },
  {
    image: 'kyuwon.jpg',
    education: [
      '인하대학교 미술과 학사',
      '인하대학교 조형예술학과 석사'
    ],
    name: '한규원',
    title: '강사',
    field: '직조/그린아트',
    likes: [
      { category: '동물', value: '고양이' },
      { category: '색갈', value: '갈색' },
      { category: '음식', value: '콩나물' },
    ],
  },
];

function People() {
  const [focusedProfile, setFocusedProfile] = React.useState<number | null>(
    null
  );
  React.useEffect(function(){
    if(focusedProfile != null){
      window.scrollTo({top: 0});
    }
  }, [focusedProfile]);
  return (
    <div className={styles.bios}>
      {BIOS.map((bio, i) => {
        const isFocused = i === focusedProfile;
        const { education, field, likes, name, image, title } = bio;
        return (
          <div className={`${styles.profile} ${isFocused ? styles.focused : ''}`} key={i}>
            <img
              src={getCaricatureURL(image)}
              alt={name}
            />
            <div className={styles.subsection}>
              <h1 className={styles.name}>
                {name}
                <button
                  className={styles.expander}
                  onClick={() => setFocusedProfile(isFocused ? null : i)}>
                  {isFocused ? '-' : '+'}
                </button>
              </h1>
              {i === focusedProfile && (
                <>
                  <h2>
                    <span className={styles.jobTitle}>{title}</span> - {field}
                  </h2>
                  <h2 className={styles.educationTitle}>학력</h2>
                  <ul>
                    {education.map((item, i) => <li key={i}>{item}</li>)}
                  </ul>
                  <h2>이런걸 좋아해요</h2>
                  <ul>
                    {likes.map((item, i) => (
                      <li key={i}>
                        좋아하는 {item.category}: {item.value}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default People;
