import React from 'react';
import { S3 } from '../API';
import styles from './MGVideo.module.css';

interface Video{
  id: number;
  postedOn: string;
  videoPath: string;
  videoPreviewPath: string;
}

interface Props {
  video: Video;
}

function MGVideo({ video }: Props) {
  return (
    <video
      controls
      className={styles.video}
      poster={S3(video.videoPreviewPath)}
      preload="none">
      <source src={S3(video.videoPath)} />
    </video>
  );
}

export default MGVideo;
