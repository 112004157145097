import * as React from 'react';
import { List } from 'immutable';

import { NotificationType, NotificationContext, INotification } from './Notification';
import { joinClasses } from 'Utility';
import styles from './NotificationBank.module.css';

interface Props {
  children: React.ReactNode;
}

interface Notification extends INotification {
  id: number;
}

let nextNotificationID: number = 0;

function NotificationBank(props: Props) {
  const [notifications, setNotifications] = React.useState(
    List<Notification>()
  );
  const onAddNotification = (notification: INotification) => {
    setNotifications(
      notifications.push({
        ...notification,
        id: nextNotificationID++,
      })
    );
  };
  const removeNotification = (notification: Notification) => {
    const index = notifications.indexOf(notification);
    setNotifications(notifications.remove(index));
  };
  const { children } = props;
  return (
    <>
      <div className={styles.bank}>
        {notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            onRemove={() => removeNotification(notification)}
          />
        ))}
      </div>
      <NotificationContext.Provider
        value={{ addNotification: onAddNotification }}>
        {children}
      </NotificationContext.Provider>
    </>
  );
}

const ItemStyles = {
  [NotificationType.Success]: styles.success,
  [NotificationType.Error]: styles.error,
  [NotificationType.Info]: styles.info,
};

function NotificationItem(props: {
  notification: Notification;
  onRemove: () => void;
}) {
  const { notification, onRemove } = props;
  React.useEffect(() => {
    const timeout = setTimeout(onRemove, 5000);
    return () => clearTimeout(timeout);
  // eslint-disable-next-line
  }, []);
  return (
    <div className={joinClasses(styles.item, ItemStyles[notification.type])}>
      {notification.message}
    </div>
  );
}

export default NotificationBank;
