import React from 'react';

import { Loading } from '../../Base';
import { getNews, getSlides } from '../../API';

import NewsList from './NewsList';
import DonateBox from './DonateBox';
import Slideshow from './Slideshow';
import Query from '../../Utility/Query';

import './Home.css';

function Home() {
  return (
    <div className="home-page">
      <div className="half left">
        <Query
          loadData={getNews}
          render={(results) => <NewsList news={results.data} />}
          loader={<Loading size={32} />}
        />
        <DonateBox />
      </div>
      <div className="half">
        <Query
          loadData={getSlides}
          render={(slides) => <Slideshow slides={slides.data} />}
          loader={<Loading size={64} />}
        />
      </div>
    </div>
  );
}

export default Home;
