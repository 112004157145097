import * as React from 'react';
import { UserErrorMessage } from '../Common/Alerts';
import User from '../Base/Types/User';

const Context = React.createContext<{
  user: null | User;
  setUser: (user: User) => void;
  logoutUser: () => void;
}
>({
  user: null,
  setUser: () => {
    throw new Error('Calling empty default setUser function');
  },
  logoutUser: () => {
    throw new Error('Calling empty default logoutUser function');
  },
});

export function useUser() {
  return React.useContext(Context);
}

export function useIsAdmin(){
  const {user} = useUser();
  return user != null && user.isAdmin;
}

export default Context;

/**
 * 
 * Utility component to wrap admin-only elements
 * Renders null if user is not admin
 */
export function AdminOnly(props: {
  children: React.ReactNode;
}): React.ReactNode {
  const { user } = useUser();
  if(user != null && user.isAdmin){
    return props.children;
  }
  return null;
}

/**
Utility class to wrap entire pages
Renders error message if user has no permission to view page
 */
export function requireAdmin<Props>(WrappedComponent: React.ComponentType<Props>) {
  return (props: Props) => {
    const { user } = useUser();
    return user && user.isAdmin ? (
      <WrappedComponent {...props} />
    ) : (
      <UserErrorMessage message="이 페이지를 보실권리가 없습니다. " />
    );
  };
}
