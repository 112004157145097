import React from 'react';
import {Board} from '../../API';
import {Link} from 'react-router-dom';
import Loading from '../../Base/Loading';
import DateDisplay from '../../Utility/DateDisplay';
import {AdminBar, ToolbarItem} from '../../Common/Toolbar';
import {LinkButton} from '../../Common/Button';
import Strings from './ThreadBrowser.strings';
import memoize from '../../Utility/memoize';
import Table from '../../Common/Table';

const COLUMNS = ['ID', 'TITLE', 'DATE'];
const COLUMN_LABELS = Object.freeze({
  ID: '#',
  TITLE: '글 제목',
  DATE: '제작시간',
});

class ThreadBrowser extends React.Component{
  state = { ready: false, threads: [] };

  async componentDidMount(){
    this.setState({
      threads: await Board.getThreads(),
      ready: true
    })
  }

  buildRows = memoize(threads => {
    return threads.map((thread, rid) => ({
      ID: rid + 1,
      TITLE: <Link to={`/board/thread/${thread.id}`}>{thread.title}</Link>,
      DATE: <DateDisplay date={thread.createdOn} />,
    }));
  });

  renderAdminBar(){
    return (
      <AdminBar>
        <ToolbarItem>
          <LinkButton buttonType="create" to="/board/thread/create">{Strings.NEW_THREAD}</LinkButton>
        </ToolbarItem>
      </AdminBar>
    );
  }


  render(){
    const {threads, ready} = this.state;
    if(!ready) return <Loading />;

    return <div className="thread-browser">
      <h1>게시판</h1>
      {this.renderAdminBar()}
      <Table cols={COLUMNS} labels={COLUMN_LABELS} rows={this.buildRows(threads)} />
    </div>;

  }
}

export default ThreadBrowser;
