import React from 'react';
import { useHistory } from 'react-router-dom';

import { requireAdmin } from 'Utility/User';
import { Form, FormRow } from 'Common/Form';
import TextBox from 'Common/TextBox';
import { Button } from 'Common/Button';
import { Upload } from 'API';
import { nullthrows } from 'Utility';
import { ContentType } from './ContentBrowser';

function FileUploader({ contentType }) {
  const history = useHistory();
  const [files, setFiles] = React.useState(null);
  const [isBusy, setIsBusy] = React.useState(false);
  const [title, setTitle] = React.useState('');

  const onSubmit = async () => {
    setIsBusy(true);

    try {
      const result = await Upload.createUpload(
        title,
        nullthrows(files, 'Should not be able to submit null files'),
        contentType
      );
      if (result.success) {
        if (contentType === ContentType.FILES) {
          history.push('/info/files');
        } else {
          history.push('/info/reports');
        }
      }
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <Form onSubmit={onSubmit} title="파일 추가하기" name="info.upload-file">
      <FormRow label="제목">
        <TextBox value={title} onChange={setTitle} />
      </FormRow>
      <FormRow label="파일">
        <input type="file" onChange={(event) => setFiles(event.target.files)} />
      </FormRow>
      <FormRow>
        <Button busy={isBusy} buttonType="submit" disabled={files == null}>
          올리기
        </Button>
      </FormRow>
    </Form>
  );
}

export default requireAdmin(FileUploader);
