import * as React from 'react';
import { FormContext } from './Form';

interface Props {
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;

  disabled?: boolean;
  isPassword?: boolean;
  placeholder?: string;
}

function TextBox(props: Props, inputRef: React.Ref<HTMLInputElement>) {
  const {
    disabled,
    isPassword,
    onChange,
    onFocus,
    onBlur,
    placeholder,
    value,
  } = props;
  const handleChange = (event: React.SyntheticEvent<HTMLInputElement>): void =>
    onChange(event.currentTarget.value);
  const { isBusy } = React.useContext(FormContext);
  return (
    <input
      disabled={isBusy || disabled}
      placeholder={placeholder}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={inputRef}
      type={isPassword === true ? 'password' : 'text'}
      value={value}
    />
  );
}

export default React.forwardRef(TextBox);
