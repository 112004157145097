import apiBase from './Query';

export async function getChildren(parent: number){
  return apiBase({
    method: 'GET',
    url: `/gallery/folders/${parent}`,
  }).then(results => results.folders);
}

export async function create(parent: number, name: string) {
  return apiBase({
    url: '/gallery/folders',
    method: 'POST',
    bodyEncodingMethod: 'json',
    data: {
      name,
      parent,
    },
  });
}

export async function move(id: number, parent: number) {
  return apiBase({
    url: '/gallery/move_folder',
    method: 'POST',
    bodyEncodingMethod: 'json',
    data: {
      id,
      parent,
    },
  });
}

export async function rename(id: number, name: string) {
  return apiBase({
    url: '/gallery/rename_folder',
    method: 'POST',
    bodyEncodingMethod: 'json',
    data: {
      id,
      name,
    },
  });
}

export async function remove(id: number) {
  return apiBase({
    url: `/gallery/folder/${id}`,
    method: 'DELETE',
  });
}
