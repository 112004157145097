import * as React from 'react';
import LINKS, {LinkCategory} from './NavigationLinks';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useUser } from '../Utility/User';
import BRAND_ICON from './mg-logo.png';
import './Navigation.css';

function Navigation() {
  const [expanded, setExpanded] = React.useState(false);
  const [activeCategory, setActiveCategory] = React.useState<LinkCategory | null>(null);
  const toggleCategory = (toggledCategory: LinkCategory) =>
    setActiveCategory(
      toggledCategory === activeCategory ? null : toggledCategory
    );
  const history = useHistory();
  React.useEffect(
    () =>
      history.listen(() => {
        setExpanded(false);
        setActiveCategory(null);
      }),
    [history, setExpanded]
  );

  const activeClass = expanded ? ' expanded-mobile' : '';
  return (
    <nav className={'top-nav' + activeClass}>
      <div className="mobile-button" onClick={() => setExpanded(!expanded)}>
        메뉴
      </div>
      <NavLink className="brand-icon" to="/">
        <img src={BRAND_ICON} className="brand-icon" alt="맵공" />
      </NavLink>
      <div className="brand-description">
        <div className="top-line">사단법인</div>
        <div className="bottom-line">엠에이피교육공작소</div>
      </div>
      <div className="top-nav-links">
        {LINKS.map((cat, i) => (
          <NavigationItem
            category={cat}
            toggleCategory={toggleCategory}
            key={i}
            isActive={cat === activeCategory}
          />
        ))}
      </div>
    </nav>
  );
}

interface NavigationItemProps {
  category: any;
  isActive: boolean;
  toggleCategory: any;
}

function NavigationItem({
  category,
  isActive,
  toggleCategory,
}: NavigationItemProps) {
  const { user } = useUser();
  return (
    <div className={`category ${isActive ? 'active-category' : ''}`}>
      <h2 onClick={() => toggleCategory(category)}>{category.title}</h2>
      <div className="links-container">
        <ul>
          {category.links.map((link: any, i: number) => {
            if (!user === link.requireAuth) {
              return null;
            }
            return (
              <li key={i}>
                <Link className="link" to={link.href}>
                  {link.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Navigation;
