const base = process.env.REACT_APP_API_URL || '/api';

type BodyData = { [key: string]: any };
type QueryHTTPMethods = 'GET' | 'PUT' | 'POST' | 'DELETE';

function parseToQueryString(paramsObj?: BodyData): string | undefined {
  if (paramsObj == null) {
    return undefined;
  }
  // TODO - gonna have to check filtering out null/undefined
  // isn't blocking anything
  return Object.keys(paramsObj)
    .filter((key) => paramsObj[key] != null)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          String(paramsObj[key])
        )}`
    )
    .join('&');
}

interface Query {
  build: () => Promise<any>;
}

interface QueryConfig {
  bodyEncodingMethod?: 'form' | 'native' | 'json';
  data?: any;
  method: QueryHTTPMethods;
  url: string;
}

class FormRequest implements Query {
  private method: QueryHTTPMethods;
  private data?: BodyData;
  private url: string;

  constructor(config: QueryConfig) {
    this.method = config.method;
    if (this.method === 'GET') {
      this.url = `${config.url}?${parseToQueryString(config.data)}`;
    } else {
      this.url = config.url;
      this.data = config.data;
    }
  }

  async build() {
    return fetch(this.url, {
      body: parseToQueryString(this.data),
      credentials: 'include',
      method: this.method,
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
}

class JSONRequest implements Query {
  private data: any;
  private method: QueryHTTPMethods;
  private url: string;

  constructor(config: QueryConfig) {
    this.data = config.data;
    this.method = config.method;
    this.url = config.url;
  }

  build() {
    return fetch(this.url, {
      body: JSON.stringify(this.data),
      credentials: 'include',
      method: this.method,
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
  }
}

class MultipartRequest implements Query {
  private data: any;
  private method: QueryHTTPMethods;
  private url: string;

  constructor(config: QueryConfig) {
    this.data = config.data;
    this.method = config.method;
    this.url = config.url;
  }

  build() {
    return fetch(this.url, {
      body: this.data,
      credentials: 'include',
      method: this.method,
    });
  }
}

/**
 * 
 * SSE related util functions
 * 
 * could possibly extract into its own module if it grows too big
 */

 /// Handle creating a SSE connection to receive upload events
export function getEventSource(path: string) {
  return new EventSource(`${base}${path}`, {
    withCredentials: true,
  });
}

/// Parse JSON from SSE
export function parseSSEEvent(event: Event) {
  if (!(event instanceof MessageEvent)) {
    throw new Error('Invalid SSE event');
  }
  return JSON.parse(event.data);
}

export default async function apiBase(input: QueryConfig) {
  let query: Query;

  // prepend base URL to all API queries
  input.url = `${base}${input.url}`;

  switch (input.bodyEncodingMethod) {
    case 'form':
      query = new FormRequest(input);
      break;
    case 'native':
      query = new MultipartRequest(input);
      break;
    case 'json':
    default:
      query = new JSONRequest(input);
      break;
  }
  const results = await query.build();
  if (results.status === 200) {
    return await results.json();
  } else if (results.status === 400) {
    // user input validation error
    const errorMsg = await results.text();
    throw new Error(errorMsg);
  } else {
    console.log(results);
    throw new Error(
      `Querying ${input.url} failed. Returned with status ${results.status}:${
        results.statusText
      }`
    );
  }
}
