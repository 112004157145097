export interface LinkCategory{
  title: string;
  links: Array<{
    text: string;
    href: string;
    requireAuth?: boolean;
  }>;
}

const LINKS: Array<LinkCategory> = [
  {
    title: '인사',
    links: [
      {
        text: '대표 인사말',
        href: '/about/hello',
      },
      {
        text: 'MAP 교육공작소는요',
        href: '/about/intro',
      },
      {
        text: '사람들',
        href: '/about/people',
      },
    ],
  },
  {
    title: '맵교육공작소',
    links: [
      {
        text: '교육 프로젝트들',
        href: '/programs',
      },
      {
        text: '블로그',
        href: '/blog',
      },
      {
        text: '갤러리',
        href: '/gallery',
      },
    ],
  },
  {
    title: '어처구니들캠프',
    links: [
      {
        text: '2019 집짓기 캠프',
        href: '/camp/2019'
      },
      {
        text: '2023 집짓기 캠프',
        href: '/camp/2023'
      }
    ]
  },
  {
    title: '경영실',
    links: [
      {
        text: '이사회 회의록',
        href: '/info/files',
      },
      {
        text: '연차 보고서',
        href: '/info/reports',
      },
    ],
  },
  {
    title: '게시판',
    links: [
      {
        text: '게시판',
        href: '/board',
      },
      {
        text: '교육일정',
        href: '/calendar',
      },
      {
        text: '언론보도',
        href: '/board/media',
      },
      {
        text: '영상모음',
        href: '/board/videos',
      },
    ],
  },
  {
    title: '회원지원',
    links: [
      {
        text: '공지사항',
        href: '/member/announcements',
      },
      {
        text: '로그인',
        href: '/member/login',
        requireAuth: false,
      },
      {
        text: '회원등록',
        href: '/member/register',
        requireAuth: false,
      },
      {
        text: '로그아웃',
        href: '/member/logout',
        requireAuth: true,
      },
      {
        text: '설정',
        href: '/member/options',
        requireAuth: true,
      },
    ],
  },
];

export default LINKS;
