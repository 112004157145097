import * as React from 'react';

import S3 from '../API/S3';

interface Props{
    alt?: string,
    className?: string,
    path: string,
}

export default function SimpleImage({alt, className, path}: Props){
    return <img alt={alt} className={className} src={S3(path)} />;
}
