import React from 'react';
import './Alerts.css';
import { nullthrows } from '../Utility';

export enum AlertType {
  ERROR,
  INFO,
  WARNING,
}

interface UserErrorMessageProps {
  error?: Error,
  message?: string,
}

export function UserErrorMessage(props: UserErrorMessageProps) {
  const { error, message } = props;
  if (!error && message === undefined) {
    return null;
  }
  return (
    <div className="user-error">{message || nullthrows(error).message}</div>
  );
}

export default (props: {
  message: string,
  type: AlertType,
}) => {
  const typeClass = `ey-alert-${props.type}`;
  return <div className={'ey-alert ' + typeClass}>{props.message}</div>;
};
