import React from 'react';

class DonateBox extends React.Component{
  render(){
    return (
      <div className="donation-info">
        <h1>후원하기</h1>
        <b>후원계좌</b>: KEB하나은행<br />
        <b>계좌번호</b>: 145-910012-28604<br />
        사단법인 엠에이피 교육공작소
      </div>
    );
  }
}

export default DonateBox;
