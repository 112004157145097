import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import ContentBrowser, { ContentType } from './ContentBrowser';
import FileUploader from './FileUploader';

export default function() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.url}/upload`}
        render={() => <FileUploader contentType={ContentType.FILES} />}
      />
      <Route render={() => <ContentBrowser type={ContentType.FILES} />} />
    </Switch>
  );
}
