export function getQueryParameter(
  queryString: string,
  parameter: string
): string | null {
  const paramRegex = new RegExp(`\\b${parameter}=(.*)&?`);
  const match = queryString.match(paramRegex);
  if (match == null) {
    return null;
  }
  return match[1];
}

export function getQueryParameterAsNumber(
  queryString: string,
  parameter: string
): number | null {
  const value = parseInt(getQueryParameter(queryString, parameter) || '');
  return isNaN(value) ? null : value;
}

export function setQueryParameter(
  queryString: string,
  parameter: string,
  value: string
) {
  queryString = queryString || '?';
  const paramRegex = new RegExp(`\\b${parameter}=([^&]*)`);
  if (paramRegex.test(queryString)) {
    return queryString.replace(paramRegex, `${parameter}=${value}`);
  } else {
    if (!/[&?]$/.test(queryString)) {
      queryString += '&';
    }
    return queryString + `${parameter}=${value}`;
  }
}
