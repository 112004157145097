import React, { SyntheticEvent, ReactNode } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import HTML5Backend, { NativeTypes } from 'react-dnd-html5-backend';
import { List, OrderedMap } from 'immutable';
import uuid from 'uuid/v4';
import FIV_CATALOG from 'file-icon-vectors/dist/icons/vivid/catalog.json';

import apiBase, { getEventSource, parseSSEEvent } from 'API/Query';
import { Loading } from 'Base';
import { joinClasses } from 'Utility';

import { Button } from './Button';
import styles from './UploaderV2.module.css';
import ErrorDrawer from './ErrorDrawer';

interface Props {
  endpoint: string;
  prepData?: (data: FormData) => void;
  options?: UploaderOptions;
}

interface UploaderOptions{
  onUpload?: (upload: Upload) => void;
  removePreviewOnSuccess?: boolean;
}

interface State {
  errors: List<string>;
  isDropHovered: boolean;
  uploadedFiles: OrderedMap<string, Upload>;
}

enum UploadStatus {
  UPLOADING = 'Uploading',
  PROCESSING = 'Processing',
  UPLOADED = 'Uploaded',
  ERROR = 'Error',
}

interface Upload {
  error: Error | undefined;
  id: string;
  status: UploadStatus;
  preview: ReactNode;
  originalName: string;
}

class Uploader extends React.Component<Props, State> {
  private clientKey = uuid();
  private eventSource: undefined | EventSource;
  private fileUploadButton = React.createRef<HTMLInputElement>();

  state: State = {
    errors: List(),
    uploadedFiles: OrderedMap(),
    isDropHovered: false,
  };

  componentDidMount() {
    const {endpoint, options} = this.props;
    const sse = getEventSource(`${endpoint}/init_sse/${this.clientKey}`);
    sse.addEventListener('SAVE_PHOTO_SUCCESS', (event) => {
      const { id } = parseSSEEvent(event);
      this.setState(({ uploadedFiles }) => {
        const upload = uploadedFiles.get(id)!;
        if(options?.onUpload){
          options.onUpload(upload);
        }
        if(options?.removePreviewOnSuccess === true){
          return {
            uploadedFiles: uploadedFiles.remove(id),
          }
        } else {
          return {
            uploadedFiles: uploadedFiles.set(id, {
              ...upload,
              status: UploadStatus.UPLOADED,
            }),
          };
        }
      });
    });
    sse.addEventListener('SAVE_PHOTO_ERROR', (event) => {
      const { id, error } = parseSSEEvent(event);
      const upload = this.state.uploadedFiles.get(id);
      this.setState((state) => ({
        errors: state.errors.push(
          `Failed to upload ${upload!.originalName}: ${error}`
        ),
        uploadedFiles: state.uploadedFiles.set(id, {
          ...upload!,
          error,
          status: UploadStatus.ERROR,
        }),
      }));
      console.warn('Backend failed to process photo:', error);
    });
    sse.addEventListener('UPLOAD_PHOTO', (event) => {
      const { id } = parseSSEEvent(event);
      this.setState(({ uploadedFiles }) => {
        const upload = uploadedFiles.get(id)!;
        return {
          uploadedFiles: uploadedFiles.set(id, {
            ...upload,
            status: UploadStatus.PROCESSING,
          }),
        };
      });
    });
    this.eventSource = sse;
  }

  componentWillUnmount() {
    this.eventSource!.close();
  }

  render() {
    const { errors, isDropHovered, uploadedFiles } = this.state;
    return (
      <div
        className={joinClasses(
          styles.root,
          isDropHovered && styles.dropHovered
        )}>
        <div className={styles.topBar}>
          <Button
            buttonType="create"
            type="button"
            onClick={this.openFileChooser}>
            <input
              type="file"
              className="hidden"
              multiple={true}
              onChange={this.onChangeFileInput}
              ref={this.fileUploadButton}
            />
            파일 선택
          </Button>
          {errors.size > 0 && <ErrorDrawer errors={errors} />}
        </div>
        <DndProvider backend={HTML5Backend}>
          <FileDropZone onDrop={this.onDropFiles}>
            {uploadedFiles.size === 0 && (
              <div className={styles.dropHint}>
                여기로 사진이나 비디오 파일을 드래그해주세요!
              </div>
            )}
            {uploadedFiles
              .map((upload) => <Upload upload={upload} key={upload.id} />)
              .valueSeq()}
          </FileDropZone>
        </DndProvider>
      </div>
    );
  }

  private openFileChooser = () => {
    this.fileUploadButton.current!.click();
  };

  private onDropFiles = (files: File[]) => {
    this.setState({ isDropHovered: false });
    this.onUpload(files);
  };

  private onChangeFileInput = (event: SyntheticEvent<HTMLInputElement>) => {
    this.onUpload(event.currentTarget.files);
  };

  private onUpload = async (files: File[] | FileList | null): Promise<void> => {
    if (files == null) {
      return;
    }
    let { uploadedFiles } = this.state;
    // steps to handle uploads
    Array.from(files).forEach((file) => {
      const fd = new FormData();
      fd.set('clientKey', this.clientKey);
      if(this.props.prepData){
        this.props.prepData(fd);
      }
      // fd.set('parent', this.props.parent.toString());
      const id = uuid();
      fd.set(id, file, file.name);
      uploadedFiles = uploadedFiles.set(id, {
        id,
        preview: null,
        status: UploadStatus.UPLOADING,
        originalName: file.name,
        error: undefined,
      });
      this.getFilePreview(file).then((preview) => {
        this.setState(({ uploadedFiles }) => {
          const upload = uploadedFiles.get(id);
          if (upload == null) {
            console.warn(
              'Preview parsing finished before setting initial state'
            );
            console.warn(`Failed to set preview image for upload ${id}`);
            return { uploadedFiles };
          }
          return {
            uploadedFiles: uploadedFiles.set(id, {
              ...upload,
              preview,
            }),
          };
        });
      });
      apiBase({
        url: `${this.props.endpoint}/upload`,
        method: 'POST',
        bodyEncodingMethod: 'native',
        data: fd,
      });
    });
    this.setState({ uploadedFiles });
    this.fileUploadButton.current!.value = ''; 
  };

  private async getFilePreview(file: File): Promise<ReactNode> {
    const FIV = (iconName: string) => (
      <span className={`fiv-viv fiv-icon-${iconName} ${styles.icon}`} />
    );
    const BLANK = FIV('blank');
    if (/image/.test(file.type)) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('error', () => resolve(BLANK));
        reader.addEventListener('load', (ev) =>
          resolve(
            <img
              alt={file.name}
              src={reader.result as string}
              className={styles.icon}
            />
          )
        );
        reader.readAsDataURL(file);
      });
    }
    const ext = file.type.substring(file.type.indexOf('/') + 1);
    return FIV_CATALOG.includes(ext) ? FIV(ext) : BLANK;
  }
}

function FileDropZone(props: { children: React.ReactNode; onDrop: any }) {
  const [{ isOver }, dropRef] = useDrop({
    accept: [NativeTypes.FILE],
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (_, monitor) => props.onDrop(monitor.getItem().files),
  });
  return (
    <div
      className={joinClasses(
        styles.previewContainer,
        isOver && styles.dropHovered
      )}
      ref={dropRef}>
      {props.children}
    </div>
  );
}

function Upload({ upload }: { upload: Upload }) {
  // TODO show error on hover
  // since it is saved
  return (
    <div className={styles.preview}>
      {upload.preview != null ? (
        upload.preview
      ) : (
        <Loading className={styles.icon} />
      )}
      <div className={styles.status}>{upload.status}</div>
    </div>
  );
}

export default Uploader;
