import React from 'react';
import icon from './Spinner-1s-200px.svg';

function Loading({ className, size }: {
  className?: string;
  size?: number;
}) {
  return (
    <img
      className={className}
      src={icon}
      alt="작업중입니다."
      style={{
        width: size ? `${size}px` : '1.3em',
        height: size ? `${size}px` : '1.3em',
        verticalAlign: 'bottom',
      }}
    />
  );
}

export function PageSpinner() {
  return <Loading size={64} />;
}

export default Loading;
