import { Redirect } from 'react-router-dom';
import React from 'react';

import { Event } from 'API';
import { Form, FormRow } from 'Common/Form';
import TextBox from 'Common/TextBox';
import TextArea from 'Common/TextArea';
import { Button } from 'Common/Button';
import { requireAdmin } from 'Utility/User';

class EventCreator extends React.Component {
  state = {
    eventDate: '',
    eventTime: '',
    title: '',
    description: '',
  };

  updateEventDate = (ev) => {
    this.setState({ eventDate: ev.currentTarget.value });
  };

  updateTitle = (title) => {
    this.setState({ title });
  };

  updateDescription = (description) => {
    this.setState({ description });
  };

  updateTime = (ev) => {
    this.setState({
      eventTime: ev.currentTarget.value,
    });
  };

  onSubmit = async () => {
    const { title, description, eventDate} = this.state;
    const eventTime = `${eventDate}T${this.state.eventTime}`;
    const results = await Event.createEvent({
      title,
      description,
      eventTime,
    });
    if (!results.success) {
      // TODO redirect to created event? Need ID back from backend
      throw new Error(
        results.error || '이벤트를 추가하는데 오류가 발생하였습니다.'
      );
    }
  };

  render() {
    const { eventDate, eventTime, title, description } = this.state;
    return (
      <Form
        onSubmit={this.onSubmit}
        successEl={<Redirect to="/calendar" />}
        validate={this._validate}>
        <h1>새 이벤트</h1>
        <FormRow label="이벤트 시간">
          <input
            type="date"
            value={eventDate}
            onChange={this.updateEventDate}
            style={{ minWidth: 'inherit' }}
          />
          <input
            type="time"
            value={eventTime}
            onChange={this.updateTime}
            style={{ minWidth: 'inherit' }}
          />
        </FormRow>
        <FormRow label="이벤트 제목">
          <TextBox value={title} onChange={this.updateTitle} />
        </FormRow>
        <FormRow label="자세한 내용">
          <TextArea value={description} onChange={this.updateDescription} />
        </FormRow>
        <FormRow>
          <Button
            disabled={!title.length || !eventTime || !eventDate}
            buttonType="submit">
            확인
          </Button>
        </FormRow>
      </Form>
    );
  }

  _validate = () => {
    const { eventDate, eventTime, title } = this.state;
    if (title.length === 0) {
      throw new Error('제목이 필요합니다.');
    }
    if (eventDate === '' || eventTime === '') {
      throw new Error('이벤트 시간을 지정하셔아합니다');
    }
  };
}

export default requireAdmin(EventCreator);
