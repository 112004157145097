import apiBase from './Query';

export function getPrograms(page) {
  return apiBase({
    method: 'GET',
    url: `/program/page/${page}`,
  });
}

export function getProgram(program) {
  return apiBase({
    method: 'GET',
    url: `/program/program/${program}`,
  });
}

export function createProgram(title, content) {
  return apiBase({
    url: `/program/`,
    method: 'POST',
    data: {
      title,
      content,
    },
  });
}

export function updateProgram(id, title, content) {
  return apiBase({
    url: `/program/`,
    method: 'PUT',
    data: {
      program: {
        id,
        title,
        content,
      },
    },
  });
}

export function deleteProgram(id) {
  return apiBase({
    url: `/program/${id}`,
    method: 'DELETE',
  });
}

export function updateAttachmentName(id, name){
  return apiBase({
    url: `/program/attachment`,
    method: 'PUT',
    data: {
      id,
      name,
    }
  })
}

export function deleteAttachment(id){
  return apiBase({
    url: `/program/attachment/${id}`,
    method: 'DELETE',
  })
}
