import { List } from 'immutable';
import React from 'react';

import Photo from '../../Base/Types/S3File';
import { Button } from '../../Common/Button';
import Image from '../../Common/SimpleImage';

import './SlideshowStrip.css';

interface Props {
  slides: List<Photo>;
  onRemove: (index: number) => void;
  onReorder: (from: number, to: number) => void;
}

function SlideshowStrip(props: Props) {
  const { onRemove, slides } = props;
  return (
    <div className="slideshow_strip">
      {slides.size > 0 ? (
        slides.map((photo, i) => (
          <div key={photo.id} className="item">
            <Image path={photo.thumbnailPath} />
            <Button className="remove_button" onClick={() => onRemove(i)}>
              &times;
            </Button>
          </div>
        ))
      ) : (
        <h2 className="mg-text-warning">지정된 사진이 없습니다 </h2>
      )}
    </div>
  );
}

export default SlideshowStrip;
