import * as React from 'react';

import { Loading } from 'Base';
import Alert from 'Common/Alerts';

class Query extends React.Component {
  state = {
    error: null,
    ready: false,
    results: null,
  };

  async componentDidMount() {
    const { loadData } = this.props;
    this.setState({ ready: false });
    try {
      const results = await loadData();
      this.setState({ results });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ ready: true });
    }
  }

  render() {
    const { error, ready, results } = this.state;
    const { render, loader } = this.props;

    if (!ready) {
      return loader || <Loading />;
    }
    if (results) {
      return render(results);
    }
    return <Alert>{error ? error.toString() : '오류가 발생하였습니다.'}</Alert>;
  }
}

export default Query;
