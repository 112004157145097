import React from 'react';
import styles from './DateDisplay.module.css';

interface Props {
  date: Date | string;
  format?: DateDisplayFormat;
}

export enum DateDisplayFormat {
  FullDate,
  DateOnly,
}

const DateDisplay = (props: Props) => {
  const { date, format = DateDisplayFormat.FullDate } = props;

  const d = new Date(date);
  let content = null;

  switch (format) {
    case DateDisplayFormat.DateOnly:
      content = d.toDateString();
      break;
    case DateDisplayFormat.FullDate:
      content = d.toLocaleString();
      break;
  }
  return <span className={styles.date}>{content}</span>;
};

export default DateDisplay;
