import React from 'react';
import { S3, Gallery } from '../API';
import { UserErrorMessage } from './Alerts';
import { AdminOnly } from '../Utility/User';
import { Button } from './Button';
import './GalleryImage.css';
import ClockwiseArrow from '../Assets/clockwise-circular-arrow.svg';
import CCwiseArrow from '../Assets/counterclockwise-circular-arrow.svg';
import Loading from '../Base/Loading';
import { nullthrows } from '../Utility';
import PopupImageContext from '../Utility/PopupImageContext';

export const SIZES = Object.freeze({
  FullSize: 1,
  MidSize: 2,
  Thumbnail: 3,
});

const buildImageURI = (path) => S3(path);

class GalleryImage extends React.Component {
  state = {
    error: null,
    isDeleted: false,
    isWorking: false,
    path: this.props.relativePath,
    expandedPath: this.props.expandedPath,
  };

  render() {
    const {
      className,
      id,
      isEditable,
    } = this.props;
    const { error, isDeleted, isWorking, path, expandedPath } = this.state;

    if (isDeleted) {
      return <div>삭제된 이미지입니다.</div>;
    }

    const imgEl = (
      <img
        alt=""
        className={`photo ${className || ''}`}
        src={buildImageURI(path)}
      />
    );

    return (
      <div className="gallery-image">
        {expandedPath ? (
          <span className="expandable-photo" onClick={this._onExpand}>
            {imgEl}
          </span>
        ) : (
          imgEl
        )}
        {id != null &&
          isEditable && (
            <AdminOnly>
              <div>
                {isWorking ? (
                  <Loading />
                ) : (
                  <React.Fragment>
                    <Button
                      buttonType="action"
                      disabled={isWorking}
                      onClick={() => this._rotateImage(90)}>
                      <img
                        src={ClockwiseArrow}
                        alt="시계 방향"
                        className="img-logo-button"
                      />
                    </Button>
                    <Button
                      buttonType="action"
                      disabled={isWorking}
                      onClick={() => this._rotateImage(270)}>
                      <img
                        src={CCwiseArrow}
                        alt="시계 반대 방향"
                        className="img-logo-button"
                      />
                    </Button>
                    <Button
                      buttonType="action"
                      disabled={isWorking}
                      onClick={this._deleteImage}>
                      &times;
                    </Button>
                  </React.Fragment>
                )}
              </div>
              <UserErrorMessage error={error} />
            </AdminOnly>
          )}
      </div>
    );
  }

  _deleteImage = async () => {
    const id = nullthrows(this.props.id);

    this.setState({ isWorking: true });
    try {
      await Gallery.deleteImage(id);
      this.setState({ isDeleted: true });
      this.props.onDelete();
    } catch (e) {
      this.setState({ error: Error('사진을 삭제하지 못했습니다.') });
    } finally {
      this.setState({ isWorking: false });
    }
  };

  async _rotateImage(deg) {
    const id = nullthrows(this.props.id);
    this.setState({ isWorking: true });
    try {
      const result = await Gallery.rotateImage(id, deg);
      if (result.success) {
        this.setState({
          path: result.photo.thumbnailPath,
          expandedPath: result.photo.resizePath,
        });
      }
    } catch (e) {
      this.setState({ error: Error('사진을 돌리지 못했습니다.') });
    } finally {
      this.setState({
        isWorking: false,
      });
    }
  }

  _onExpand = () => {
    const { showImage } = this.props;
    showImage(buildImageURI(this.state.expandedPath));
  };
}

export default (props) => (
  <GalleryImage
    {...props}
    showImage={React.useContext(PopupImageContext).showImage}
  />
);
